<template>
  <div
    ref="rootNode"
  >
  </div>
</template>

<script>
import * as THREE from 'three';
import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/addons/postprocessing/ShaderPass.js';
import { GlitchPass } from 'three/addons/postprocessing/GlitchPass.js';
import { OutlinePass } from 'three/addons/postprocessing/OutlinePass.js';
import { OutputPass } from 'three/addons/postprocessing/OutputPass.js';
import { FXAAShader } from 'three/addons/shaders/FXAAShader.js';
import { inject, nextTick, onBeforeUnmount, onMounted, provide, ref, toRef, toRaw, watch } from 'vue';

export default {
  name: 'Composer',
  "components": {
  },
  "props": {
      sceneLoaded: false
  },
  setup(props, {emit}) {

    var outlinePass;

    const rootNode = ref(),
    sceneLoaded = toRef(props.sceneLoaded),
    renderer = inject('renderer'),
    scene = inject('scene'),
    camera = inject('camera'),
    mouseInfo = inject('mouseInfo'),
    //composer = new EffectComposer(renderer),
    composer = ref(),
    width = ref(),
    height = ref(),
    keyPress = ref({}),
    init = () => {

      width.value = window.innerWidth;
      height.value = window.innerHeight;

      composer.value = new EffectComposer(renderer);

      const cam = camera.value.get();
      const renderPass = new RenderPass( scene, cam );

      composer.value.addPass( renderPass );

      outlinePass = new OutlinePass( new THREE.Vector2( window.innerWidth, window.innerHeight ), scene, cam );
      composer.value.addPass( outlinePass );

/*
      const glitchPass = new GlitchPass();
      composer.value.addPass( glitchPass )
*/
      const outputPass = new OutputPass();
      composer.value.addPass( outputPass );

/*
      const effectFXAA = new ShaderPass( FXAAShader );
      effectFXAA.uniforms[ 'resolution' ].value.set( 1 / window.innerWidth, 1 / window.innerHeight );
      composer.addPass( effectFXAA );
*/

    },
    addOutline = (node, opts) => {

        outlinePass.edgeStrength = Number( opts.edgeStrength );
        outlinePass.edgeGlow = Number( opts.edgeGlow);
        outlinePass.edgeThickness = Number( opts.edgeThickness);
        outlinePass.pulsePeriod = Number( opts.pulsePeriod);
        outlinePass.rotate = Number( opts.rotate);
        outlinePass.usePatternTexture = Number( opts.usePatternTexture);
        outlinePass.visibleEdgeColor.set( 0xffa500  );
        outlinePass.hiddenEdgeColor.set( 0x00ffff );
        outlinePass.selectedObjects = [ node ];

    };

    onMounted(() => {

        init();

        watch(
            () => sceneLoaded.value,

            (first, second) => {

              console.log("SCENE LOADED CHANED")
              if (sceneLoaded == true) {
 //               init();
              }

            }

        );

        watch(
            () => mouseInfo.value,

            (first, second) => {

                if (mouseInfo.value.buttons == 2) {

                    // camera.value.orient();

                }

            },
            { deep: true }
        );

    });

    onBeforeUnmount(() => {

        composer.value.dispose();

    });

    return {
      rootNode,
      keyPress,
      width,
      height,
      init,
      scene,
      composer,
      addOutline,
      sceneLoaded
    };
  },
};
</script>

<style>
body {
  margin: 0;
}

#threeContainer {
  width: 100%;
  height: 100vh; /* Adjust as needed */
}
</style>
