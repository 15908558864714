
export function build(material, uniforms) {

    material.onBeforeCompile = (shader) => {

        shader.uniforms.noise = uniforms.noise;
        shader.uniforms.normalMap = uniforms.normalMap;
        shader.uniforms.splatMap = uniforms.splatMap;
        shader.uniforms.rockTexture = uniforms.rockTexture;
        shader.uniforms.sunPosition = uniforms.sunPosition;
        shader.uniforms.viewDirection = uniforms.viewDirection;
        shader.uniforms.mergeDistance = uniforms.mergeDistance;
        shader.uniforms.mergeTexture = uniforms.mergeTexture;
        shader.uniforms.macroMapSize = uniforms.macroMapSize;

        // outside main
        shader.vertexShader = shader.vertexShader.replace(
          `#include <common>`,
          `
          #include <common>

          //precision mediump float;
          //precision mediump int;

          // these need to be added if normalmap texture is not provided
          attribute vec3 tangent; 
          attribute vec3 bitangent;
          //attribute vec2 uv;

          //varying vec3 vtWorldPosition;
          varying vec3 vWorldPosition;
          varying vec2 vUv;

          varying float slope;
          varying float vFresnel;
          varying float vFresnel2;
          varying float vFresnel4;
          varying vec3 vNewNormal;

          uniform vec3 viewDirection;
          uniform vec3 sunPosition;
          uniform float mergeDistance;
          uniform sampler2D mergeTexture;
          uniform float macroMapSize;

          varying vec3 forwardVector;
          varying vec3 lightDirection;

          `
        );

        // inside main
        shader.vertexShader = shader.vertexShader.replace(
          `#include <project_vertex>`,
          `
          #include <project_vertex>

          vUv = uv; // covers segment, typically used for repeating textures.

          `
        );

        // set positions
        shader.vertexShader = shader.vertexShader.replace(
          `#include <worldpos_vertex>`,
          `
          #include <worldpos_vertex>

          vWorldPosition = (modelMatrix * vec4(position, 1.0)).xyz;

          float distanceToCamera = distance(vWorldPosition, cameraPosition);

 //         float mergeFactor = 1.0 - smoothstep(0.0, mergeDistance, distanceToCamera);
 //         float mergeFactor = step(mergeDistance, distanceToCamera);
 //         float mergeFactor = step(distanceToCamera, mergeDistance);

 /*
          float mergeFactor = 1.0 - smoothstep(mergeDistance - 0.1, mergeDistance, distanceToCamera);

          vec4 displacedPosition = vec4(transformed, 1.0);
          displacedPosition.z -= (mergeFactor * 100.0);
          gl_Position = projectionMatrix * viewMatrix * modelMatrix * displacedPosition;
  */

          float tx = (vWorldPosition.x / macroMapSize);
          float ty = (vWorldPosition.y / macroMapSize);
          //float mask = texture2D(mergeTexture, vec2(tx, ty)).r; // Sample mask texture
          float mask = texture2D(mergeTexture, vUv).r;

          vec4 displacedPosition = vec4(transformed, 1.0);

          displacedPosition.z -= mask * 100.0; // Depress based on mask
          //displacedPosition.z = 0.0;
          gl_Position = projectionMatrix * viewMatrix * modelMatrix * displacedPosition;

          forwardVector = normalize(viewDirection);
          //lightDirection = normalize(-sunPosition);
          //lightDirection = normalize(sunPosition - vWorldPosition);

          vFresnel = 1.0 - clamp(dot(forwardVector, normal), 0.0, 1.0);
          vFresnel2 = pow(vFresnel, 2.0);
          vFresnel4 = pow(vFresnel, 4.0);

          `
        );

        shader.vertexShader = shader.vertexShader.replace(
          `#include <defaultnormal_vertex>`,
          `
          #include <defaultnormal_vertex>

          `
        );

        shader.onBeforeCompile = (shader) => {
          shader.fragmentShader = shader.fragmentShader.replace(
            'specular = vec3(0.0);'
          );
        };

        // outside main
        shader.fragmentShader = shader.fragmentShader.replace(
          `#include <common>`,
          `
          #include <common>

          uniform mat3 normalMatrix;
          uniform mat4 modelMatrix;
          uniform mat4 modelViewMatrix;
          uniform sampler2D noise;
          uniform sampler2D splatMap;
          uniform sampler2D normalMap;
          uniform sampler2D rockTexture;

          varying vec2 vUv;
          varying vec3 vWorldPosition;
          varying vec3 vNewNormal;

          varying float vFresnel;
          varying float vFresnel2;
          varying float vFresnel4;

          uniform vec3 sunPosition;
          uniform vec3 viewDirection;

          uniform vec3 forwardVector;
          uniform vec3 lightDirection;

          uniform sampler2D mergeTexture; // delme

          struct DetileData {
              vec2 offa;
              vec2 offb;
              vec2 dx;
              vec2 dy;
              float blendFactor;
          };

          float sum( vec3 v ) { return v.x+v.y+v.z; }

          float valueNoise(vec2 uv) {
              vec2 p0 = floor(uv);
              vec2 p1 = p0 + vec2(1.0, 0.0);
              vec2 p2 = p0 + vec2(0.0, 1.0);
              vec2 p3 = p0 + vec2(1.0, 1.0);

              float v0 = fract(sin(p0.x + p0.y * 64.0) * 43758.5453);
              float v1 = fract(sin(p1.x + p1.y * 64.0) * 43758.5453);
              float v2 = fract(sin(p2.x + p2.y * 64.0) * 43758.5453);
              float v3 = fract(sin(p3.x + p3.y * 64.0) * 43758.5453);

              float tx = uv.x - p0.x;
              float ty = uv.y - p0.y;
              float v0_v1 = mix(v0, v1, tx);
              float v2_v3 = mix(v2, v3, tx);
              return mix(v0_v1, v2_v3, ty);
          }

          float getSlopeFactor(vec3 normal) {

              return 1.0 - abs(dot(normal, vec3(0.0, 1.0, 0.0)));

          }

          // function to randomize textures to prevent tiling effect, based off
          // https://iquilezles.org/articles/texturerepetition/
          //
          DetileData computeDetilingOffset(vec2 uv, vec4 noise) {

              float k = valueNoise(uv);
              //float k = fract(sin(dot(vec2(1.0,2.0), vec2(12.9898, 78.233))) * 43758.5453);
              //float k = fract(sin(dot(vec2(1.0,2.0), noise.rg)) * 43758.5453);
              float l = k * 8.0;
              float f = fract(l);

              float ia = floor(l);
              float ib = ia + 1.0;

              vec2 dx = dFdx(uv);
              vec2 dy = dFdy(uv);

              vec2 offa = sin(vec2(3.0, 7.0) * ia);
              vec2 offb = sin(vec2(3.0, 7.0) * ib);

              float blendFactor = smoothstep(0.2, 0.8, f-0.1);

              return DetileData(uv + offa, uv + offb, dx, dy, blendFactor);

          }

          `
        );

        // inside main
        shader.fragmentShader = shader.fragmentShader.replace(
          `#include <map_fragment>`,
          `
          //#include <map_fragment>

          //#include <normal_pars_fragment>
          //#include <normalmap_pars_fragment>

          vec4 noiseTex = texture(noise, vUv * 8.0);
          vec4 noiseValue = texture(noise, floor(vUv * 16.0) / 16.0);
          vec4 normalTex = texture(normalMap, vUv);
          vec4 splatTex = texture(splatMap, vUv);
          vec4 rockDiffuse = texture(rockTexture, vUv * 32.0);

          // Load tangent normal texture
          //vec3 tangentNormal = normalize(normalTex.rgb * 2.0 - 1.0);
          vec3 tangentNormal = normalize(vec3(normalTex.r, normalTex.g, normalTex.b) * 2.0 - 1.0);
          tangentNormal.x *= -1.0;
          tangentNormal.y *= -1.0;
          vec3 worldTangentNormal = normalize(mat3(normalMatrix) * tangentNormal);

/*
          vec3 lightGrass = vec3(0.335, 0.325, 0.025);
          vec3 darkGrass = vec3(0.235, 0.255, 0.005);
          vec3 rockColor = vec3(0.8, 0.8, 0.9);
*/

          vec3 lightGrass = vec3(0.67, 0.65, 0.05);
          vec3 darkGrass = vec3(0.47, 0.55, 0.01);
          vec3 rockColor = vec3(0.8, 0.8, 0.6);

          rockColor = rockDiffuse.xyz;

          DetileData detileData = computeDetilingOffset(vUv * 4.0, noiseValue);

          // calculate slope
          //float slope = getSlopeFactor(tangentNormal);
          float slope = abs(tangentNormal.y);

          // distance from camera
          float distance = length(vWorldPosition.xyz - cameraPosition);

          float grassFactor = smoothstep(0.1, 0.16, slope);
          float rockFactor = smoothstep(0.16, 0.22, slope); // grass blends over rock
          
          rockColor = rockColor * max(rockFactor, 0.3);
          vec3 grassColor = mix(darkGrass, lightGrass, grassFactor);
          vec4 diffuse = vec4(mix(grassColor, rockColor, rockFactor), 1.0);

          vec3 noiseVar = mix(vec3(0.75), vec3(1.25), noiseTex.rgb); // large noise
          //diffuse.rgb = diffuse.rgb * noiseVar * grassFactor;
          diffuse.rgb *= noiseVar;

          //diffuseColor.rgb = texture2D(mergeTexture, vUv).rgb;
          diffuseColor *= diffuse;

          // #include <dithering_fragment>
          `
        );

        shader.fragmentShader = shader.fragmentShader.replace(
          `#include <normal_fragment_maps>`,
          `
          #include <normal_fragment_maps>
          // Use the modified normal in the fragment shader
          normal = worldTangentNormal;
          `
        );

      /*
        // inside main
        shader.fragmentShader = shader.fragmentShader.replace(
          `#include <roughnessmap_fragment>`,
          `
          float roughnessFactor = roughness4.g * roughness;

          #ifdef USE_ROUGHNESSMAP

            vec4 texelRoughness = texture( roughnessMap, vRoughnessMapUv );

            // reads channel G, compatible with a combined OcclusionRoughnessMetallic (RGB) texture
            // roughnessFactor *= texelRoughness.g;
            roughnessFactor = mix(roughnessFactor, texelRoughness.g, 0.5);

          #endif
          `
        );
*/

        // inside main
        shader.fragmentShader = shader.fragmentShader.replace(
          `#include <lights_fragment_begin>`,
          `

          float materialFactor = smoothstep(1.0, 10.0, distance);

          // boost final normal
          //normal = normalize(normal * 10.0);

          #include <lights_fragment_begin>

          // calculate emissive light for stylized effect based on roughness map
          float emissiveFactor = 1.0;

          // Fresnel for iridescence stylized effect
          float grassFresnel = vFresnel;
          float rockFresnel = vFresnel2;

          // Rock and grass emissive 
          vec3 rockEmissive = 0.5 * rockColor;
          vec3 grassEmissive = 0.5 * lightGrass;

          // Iridescence based on Fresnel
          vec3 rockIridescence = 0.5 * rockColor * rockFresnel;
          vec3 grassIridescence = 0.7 * lightGrass * grassFresnel * grassFactor;

          float angle = dot(forwardVector, lightDirection);

          // Combine iridescence and emissive effects
          vec3 iridescence = mix(grassFresnel * grassIridescence, rockFresnel * rockIridescence, 0.5);
          float fresnelEdge = pow(1.0 - abs(dot(forwardVector, normal)), 1.0);
          vec3 emissive = emissiveFactor * (rockEmissive + grassEmissive) + iridescence * fresnelEdge;

          //#include <lights_fragment_begin>
          `
        );

      /*
        shader.fragmentShader = shader.fragmentShader.replace(
          'reflectedLight.indirectDiffuse += radiance * BRDF_Diffuse_Lambert( diffuseColor.rgb );',
          'reflectedLight.indirectDiffuse = radiance * BRDF_Diffuse_Lambert( diffuseColor.rgb );'
        );
*/
        shader.fragmentShader = shader.fragmentShader.replace(
          `#include <lights_fragment_end>`,
          `
          // Add the iridescence effect to the final lighting

          reflectedLight.directDiffuse = clamp(reflectedLight.directDiffuse + reflectedLight.directDiffuse * emissive, 0.0, 1.0);
          //reflectedLight.directDiffuse = clamp(reflectedLight.directDiffuse, 0.0, 1.0);

          #include <lights_fragment_end>
          `
        );

    return material;
}

};
