<template>
  <div ref="rootNode">
    <div />
  </div>
</template>

<script>

import * as THREE from 'three';
import { emits, inject, onMounted, onUnmounted, ref, toRaw, watch } from 'vue';
import Camera from './Camera.vue';
import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js';
import { OBJExporter } from 'three/addons/exporters/OBJExporter.js';
import { OBJLoader } from 'three/addons/loaders/OBJLoader.js';
import { decode } from 'fast-png';
import Martini from '@mapbox/martini'
//import jpeg from 'jpeg-js';
import * as landscapeShader from './LandscapeShader.js'
import * as macroLandscapeShader from './MacroLandscapeShader.js'

export default {
    name: 'Terrain',
        "components": {
        },
        "props": {
            params: {
                type: Object,
                required: true,
            },
            surfaceMesh: {
                type: Object,
                required: true
            }
        },
        "emits": [],
        setup(props, { emit }) {

            const scene = inject('scene'),
            axios = inject('Axios'),
            camera = inject('camera'),
            keyPress = inject('keyPress'),
            renderer = inject('renderer'),
            rootNode = ref(null),
            textureMaps = [],
            buffers = {},
            scale = ref(1),
            spg = ref(1),
            worker = new Worker(new URL('./terrain-worker.js', import.meta.url)),
            trackPosition = new THREE.Vector3(),
            textureLoader = new THREE.TextureLoader(),
            noiseTexture = textureLoader.load("public/textures/noise.jpg"),
            activeSegments = ref([]),
            viewDistance = ref(1000),
            gridStartX = ref(0), // start of grid x
            gridStartY = ref(0), // start of grid y
            setCameraPosition = ref(true),
            terrainNode = ref(new THREE.Group()),
            mergeTexture = ref(),
            mergeTextureData = ref(),
            terrainData = ref({}),
            terrainMaterial = ref({}),
            cacheMap = ref({}),
            debounceKey = ref({}),
            activeLights = {},
            mapsegs = ref({}), // map segments ( based on lod size vs grid size, eg 4 for 1024 grid / 256 res textures)
            mapmats = ref({}), // map materials ( 1 per grid. typically 1 - 3 materials at any given time: TODO: dynamic streaming)
            heightMaps = ref({}),
            decimateMaps = ref({}),
            normalMaps = ref({}),
            inGridUpdate = ref(false),
            geometryQueue = {},
            currentGrid = ref([]),
            mergePairs = {}, // pre-calculated lod neighbor segment vertex pairs for merging normals
            inUpdate = ref(false),
            updateDelta = ref(125), // how far we the camera move without updating
            loader = new THREE.ObjectLoader(),
            /*
             * Overview ! So how is this map architected ?
             * We have segments in json that contain the data of the map, here is a segment sample
             *   {
             *     "terrain": {
             *       "scale": 1,
             *       "lods": [256, 128, 64, 32, 16, 8],
             *       "position": [0,0,0],
             *       "rotation": [90,0,0],
             *       "grids": [{
             *         "id": [-3,3],
             *         "position": [0,0,0],
             *         "rotation": [0,0,0],
             *         "alphablack": false,
             *         "heightmap": "public/terrain/tile-00001.png"
             *       },
             *
             * So each grid will have data on a high level with a grid x,y id, think GPS grid reference
             * The map then behaves as a window that runs over these grids and can span multiple grids
             *
             * Within a map then there are multiple mesh segments, that make
             *
             *
             */
            loadTerrain = () => {

                axios.get(
                  //`${process.env.VUE_APP_SERVER_URI}public/terrain.json`,
                  `https://www.intradeep.com/public/terrain.json`,
                  {

                      "headers": {
                      }

                  }

                ).
                  then(async (response) => {

                      if (response.status === 200) {

                          const n = response.data;
                          terrainData.value = n.terrain;
                          scale.value = terrainData.value.scale;

                          calculateMergePairs();
                          await loadTextures();

                          if (terrainData.value.viewDistance) {

                              viewDistance.value = terrainData.value.viewDistance;

                          }

                          // get grid bounds and info
                          getGridData();

                          // build macromaps
                          buildMacroMap();

                          // build main map
                          let range = getGridRange();
                          loadMaps(range.start,range.end);

                      }

                }).
                catch((error) => {console.log(error)});

            },
            loadMaps = async (start, end) => {
//debug
                // load the height and normal data in worker  
                for (var i=0; i< terrainData.value.grids.length; i++) {

                    const offset = terrainData.value.heightOffset;
                    const gridX = terrainData.value.grids[i].id[0];
                    const gridY = terrainData.value.grids[i].id[1];

                    if ((gridX >= start[0] && gridX <= end[0]) && (gridY >= end[1] && gridY <= start[1])) {

                        if (heightMaps.value[`${gridX},${gridY}`]) {

                            continue;

                        }

                        const z = terrainData.value.heightScale > 0 ?
                          terrainData.value.heightScale: 1.0;

                        worker.postMessage({
                            action: 'getHeightMap',
                            request: {
                              image:terrainData.value.grids[i].heightmap,
                              gridX,
                              gridY
                            }
                        });

                    }

                }

                //document.hidden = true; 
                //document.visibilityState = "hidden"; 

            },
            buildMap = async (start, end) => {

                var startTime = performance.now();

                // array to hold worker request data
                const buildData = [];

                // start and end are in grid coordinates, convert to segments
                // (why +1? grid 0=1->4,1=4->8 etc. so grid 1->2 needs to be 8 in length, not 4) 
                // why start and end are reversed ? because we iterate top to bottom
                const sStart = [start[0]*spg.value, (start[1]+1)*spg.value]; // segment range start
                const sEnd = [(end[0]+1)*spg.value, end[1]*spg.value]; // segment range end (why +1? 0=1->4,1=4->8 etc.)
                const sViewSize = sStart[1] - sStart[0]; // segment view size

                let x, y, z;

                const halfMap = (terrainData.value.gridSize * terrainData.value.mapSize/2) - terrainData.value.gridSize / spg.value;
                const centermap = new THREE.Vector3(halfMap, 0, halfMap);

                for (let i = sStart[0]; i < sEnd[0]; i++) {

                    x = (i * terrainData.value.lods[0] - terrainData.value.lods[0]/2) * terrainData.value.scale;

                    for (let j = sEnd[1]; j < sStart[1]; j++) {

                        y = (j * terrainData.value.lods[0] - terrainData.value.lods[0]/2) * terrainData.value.scale;

                        // do not process duplicates
                        if (mapsegs[`${i},${j}`]) {

                            continue;

                        }

                        if (j < 0 || i <0) {

                            continue;

                        }

                        // REPLACE WITH BETTER CODE
                        const gridX = Math.floor(i / spg.value) + gridStartX.value;
                        const gridY = Math.floor(j / spg.value) + gridStartY.value;
                        var p = 0
                        for (var q=0; q<terrainData.value.grids.length; q++) {

                            if (terrainData.value.grids[q].id[0] == gridX && terrainData.value.grids[q].id[1] == gridY) {

                                p = q;

                            }

                        }

                        buildData.push({
                            x,y,
                            i,j,
                            spg:spg.value,
                            size:terrainData.value.lods[0],
                            heightScale:terrainData.value.heightScale,
                            heightOffset:terrainData.value.heightOffset,
                            gridX,gridY,
                            //grid:terrainData.value.grids[p],
                            splatmap:terrainData.value.grids[p].splatmap,
                            normalmap:terrainData.value.grids[p].normalmap
                            //normalmap:"public/textures/uvchecker.png"

                        });
                        
                    }

                }

/*
                for (const key in heightMaps.value) {
                    heightMaps.value[key].fill(0); // Clear memory
                    heightMaps.value[key] = null;
                }
*/

                worker.postMessage({
                    action: 'buildSegmentData',
                    request: buildData
                });

            },
            loadTextures = async (callback) => {

                const textures = [

                    // 0 diffuse
                    [
                      //"public/textures/uvchecker.png",
                      "public/textures/plain_dirt_close_diffuse.jpg",
                      "public/textures/stylized_grass_5_diffuse.jpg",
                      "public/textures/gravel_close_diffuse.jpg",
                      "public/textures/rock1_close_diffuse.jpg",
                      "public/textures/stylized_cliff_1_diffuse.jpg"
                      //"public/textures/rock2_close_diffuse.jpg"
                    ],
                    // 1 normal
                    // Problems with normals.. it is Not.
                    // DataArrayPacking
                    // ColorSpace
                    // It is probably the texture because when I use the gaea texture it works...
                    [
                      //"public/terrain/normal-04-04.jpg",
                      "public/textures/plain_dirt_close_normal.jpg",
                      "public/textures/stylized_grass_5_normal.jpg",
                      "public/textures/gravel_close_normal.jpg",
                      "public/textures/rock1_close_normal.jpg",
                      "public/textures/stylized_cliff_1_normal.jpg"
                      //"public/textures/rock2_close_normal.jpg"
                    ],
                    // roughness maps
                    [
                      "public/textures/plain_dirt_close_spec.jpg",
                      "public/textures/stylized_grass_5_spec.jpg",
                      "public/textures/gravel_close_spec.jpg",
                      "public/textures/rock1_close_spec.jpg",
                      "public/textures/stylized_cliff_1_spec.jpg"
                      //"public/textures/rock2_close_spec.jpg"
                    ],
                    // aomaps
                    [
                      "public/textures/plain_dirt_close_ao.jpg",
                      "public/textures/stylized_grass_5_ao.jpg",
                      "public/textures/gravel_close_ao.jpg",
                      "public/textures/rock1_close_ao.jpg",
                      "public/textures/stylized_cliff_1_ao.jpg"
                      //"public/textures/rock2_close_ao.jpg"
                    ]

                ]

                for (let t = 0; t < textures.length; t++) {

                    const dataArr = new Uint8Array(textures[t].length * 4 * 1024 * 1024);

                    for (let i = 0; i < textures[t].length; i++) {

                        const {width,height,data} = await loadImage(textures[t][i]);
                        const offset = i * (4 * 1024 * 1024);

                        dataArr.set(data, offset);

                    }

                    //textureMaps[t] = new THREE.DataTexture2DArray(dataArr, 1024, 1024, textures[t].length);
                    textureMaps[t] = new THREE.DataArrayTexture(dataArr, 1024, 1024, textures[t].length);

                    // normal (typically normals should be in RGBA Format)
                    if (t === 1) {

     //                   textureMaps[t].encoding = THREE.LinearEncoding;
                        //textureMaps[t].format = THREE.LinearSRGBColorSpace;
                        //textureMaps[t].type = THREE.UnsignedByteType;
                        textureMaps[t].format = THREE.RGBAFormat;
                        textureMaps[t].colorspace = THREE.NoColorSpace;
                        textureMaps[t].minFilter = THREE.LinearMipMapLinearFilter;
                        textureMaps[t].magFilter = THREE.LinearFilter;
                        textureMaps[t].wrapS = THREE.RepeatWrapping;
                        textureMaps[t].wrapT = THREE.RepeatWrapping;
                        //textureMaps[t].repeat.set(1, 1);
                        textureMaps[t].generateMipmaps = true;
                        textureMaps[t].needsUpdate = true;

                    } else {

                        textureMaps[t].encoding = THREE.sRGBEncoding;
                        textureMaps[t].format = THREE.RGBAFormat;
                        //textureMaps[t].format = THREE.LinearSRGBColorSpace;
                        textureMaps[t].type = THREE.UnsignedByteType;
                        textureMaps[t].minFilter = THREE.LinearMipMapLinearFilter;
                        textureMaps[t].magFilter = THREE.LinearFilter;
                        textureMaps[t].wrapS = THREE.RepeatWrapping;
                        textureMaps[t].wrapT = THREE.RepeatWrapping;
                        //textureMaps[t].repeat.set(1, 1);
                        textureMaps[t].generateMipmaps = true;
                        textureMaps[t].needsUpdate = true;
                    }

                }

                // noise texture
                noiseTexture.colorSpace = THREE.LinearSRGBColorSpace;
                noiseTexture.minFilter = THREE.LinearMipMapLinearFilter;
                noiseTexture.magFilter = THREE.LinearFilter;
                noiseTexture.wrapT = THREE.RepeatWrapping;
                noiseTexture.wrapS = THREE.RepeatWrapping;
                noiseTexture.generateMipmaps = true;

            },
            /*
             * This is the main update function that streams new terrain in, this works relatively well but there are issues
             *
             * - loading buffer geometry is kinda slow, but this needs to be done in the main thread, it is a limitation
             *   of web workers and gpu access. Right now we use the web worker to build the terrain data and then just generate
             *   the result in the main thread in a somewhat async way that tries not to block much.
             * - potentially we could move pass 1 lod update into the webworker too, it may save 30 odd ms every 3 seconds.
             * - TODO : we need to have some mechanism to save data and edit the mesh, so like some meshes could have custom 
             *   data, caves etc, for thos segments we could maybe save / load from threejs object format or use obj loader /
             *   exporter. Seams may be an issue though. Say you have 5 lods and 16 combinations of seams, it's going to be a
             *   problematic, how can we manage seams... maybe generate them separately ?
             *   Here is a possible solution
             *   - each LOD is basically a grid so you can change / store the x,y,z of custom vertices, the indices don't
             *     change, this can allow for overhangs, we just store each custom segment for each lod, the stitchs should
             *     behave the same as the vertex xy counts are the same.
             *   - For caves , caverns of any other more complex customizations, the developer needs to mark the vertices
             *     that are to be removed and supply custom models for each LOD that match, I'm unsure how stitches will
             *     work, but it will probably be problematic, the developer will need to supply models that can work in each
             *     situation, that can be somewhat complext.
             *   - The only other way would be to do away with stiches and find some other solution, meshlets or something.
             */
            updateLOD = () => {

                var startTime = performance.now();

                // first pass : calculate and set LOD for each segment.
                for (let i=0; i < terrainNode.value.children.length; i++) {

                    const worldPosition = new THREE.Vector3();
                    terrainNode.value.children[i].getWorldPosition(worldPosition);

                    const d = worldPosition.distanceTo(camera.value.camera.position),
                      ti = terrainNode.value.children[i]?.userData?.terrainInfo;

                    const prevLod = ti.lod;
                    ti.prevLod = ti.lod;

                    // Calculate LOD based on distance
                    let distance = 0.75;
                    for (var l in terrainData.value.lods) {

                        if (d < ti.lods[0] * ti.scale * distance) {
                            ti.lod = parseInt(l);
                            ti.distance = distance;
                            break;
                        }
                        distance += 1;

                    }

                }

                var endTime = performance.now()
                console.log(`update pass 1 took ${endTime - startTime} milliseconds`)

                var startTime = performance.now()

                // we batch the request data in here and pass it all to a worker thread at the end

                // segments that have a different lod
                const newTerrain = [];
                // segments that have the same lod but different edge
                const updateTerrain = [];

                // Second pass, render based on LOD
                for (let i=0; i < terrainNode.value.children.length; i++) {

                    const ti = terrainNode.value.children[i].userData.terrainInfo,
                      c = terrainNode.value.children,
                      // Calculate edges based on neighbors (n,e,s,w)
                      edgeBounds = [
                        (c[i].userData?.terrainInfo?.lod + 1 == c[i].userData?.terrainInfo?.neighbors?.north?.userData?.terrainInfo?.lod),
                        (c[i].userData?.terrainInfo?.lod + 1 == c[i].userData?.terrainInfo?.neighbors?.east?.userData?.terrainInfo?.lod),
                        (c[i].userData?.terrainInfo?.lod + 1 == c[i].userData?.terrainInfo?.neighbors?.south?.userData?.terrainInfo?.lod),
                        (c[i].userData?.terrainInfo?.lod + 1 == c[i].userData?.terrainInfo?.neighbors?.west?.userData?.terrainInfo?.lod),
                      ];

                    ti.debugData = {
                      "this": c[i].userData?.terrainInfo?.lod,
                      "north": c[i].userData?.terrainInfo?.neighbors?.north?.userData?.terrainInfo?.lod,
                      "east": c[i].userData?.terrainInfo?.neighbors?.east?.userData?.terrainInfo?.lod,
                      "south": c[i].userData?.terrainInfo?.neighbors?.south?.userData?.terrainInfo?.lod,
                      "west": c[i].userData?.terrainInfo?.neighbors?.west?.userData?.terrainInfo?.lod
                    }

                    ti.edgeBounds = ti.edgeBounds ?? [];

                    // only update when necessary
                    var newEdge = false;
                    for (var j=0; j < edgeBounds.length; j++) {

                        if (edgeBounds[j] != ti.edgeBounds[j]) {

                            newEdge = true;

                        }

                    }

                    ti.edgeBounds = edgeBounds;

                    // skip lowest LOD , this is done with macromap now
                    if (ti.lod > terrainData.value.lods.length - 1) {

                        continue;

                    }

                    // add new terrain
                    if ((ti.prevLod != ti.lod) // does not have the same lod
                      || (buffers[c[i].userData?.terrainInfo?.coords] == undefined)
                    ) {

                        newTerrain.push([
                          ti.lods[0],
                          ti.lods[0],
                          ti.lods[ti.lod],
                          ti.lods[ti.lod],
                          edgeBounds,
                          c[i].userData?.terrainInfo?.coords,
                          toRaw(c[i].userData?.terrainInfo?.uvShift),
                          toRaw(c[i].userData?.terrainInfo?.height),
                          [],
                          [],
                          scale.value
                        ]);

                    }

                    if ((ti.prevLod === ti.lod) // has the same lod
                      && (newEdge == true) // needs a new lod seam
                      && (ti.distance < viewDistance.value + terrainData.value.lods[0]) // is within viewdistance
                      && (buffers[c[i].userData?.terrainInfo?.coords]) // a buffer already exists
                    ) {

                        //updateTerrain.push([
                        newTerrain.push([
                          ti.lods[0],
                          ti.lods[0],
                          ti.lods[ti.lod],
                          ti.lods[ti.lod],
                          edgeBounds,
                          c[i].userData?.terrainInfo?.coords,
                          toRaw(c[i].userData?.terrainInfo?.uvShift),
                          toRaw(c[i].userData?.terrainInfo?.height),
                          [],
                          [],
                          scale.value
                        ]);

                    }

                }

                var endTime = performance.now();
                console.log(`update pass 2 took ${endTime - startTime} milliseconds`)

                return {newTerrain, updateTerrain};

            },
            trackCamera = (immediate) => {

                const grid = getCurrentGrid();
                const d = trackPosition.distanceTo(camera.value.camera.position);
                const cg = toRaw(currentGrid.value);

                // update map grids if camera has moved sufficiently  
                //if ((cg[0] != grid[0]) || (cg[1] != grid[1]) && inGridUpdate.value === false) {
                if ((cg[0] != grid[0]) || (cg[1] != grid[1])) {
      
                    inGridUpdate.value = true;
                    let range = getGridRange();
                    currentGrid.value = grid;
                    loadMaps(range.start,range.end);

                }

                // update segments if camera has moved sufficiently
                if (grid) {

                    if ((d > terrainData.value.lods[0]/2 || immediate === true) && Object.keys(geometryQueue).length === 0) {
                        immediate = false;

                        trackPosition.copy(camera.value.camera.position);
                        let {newTerrain:newTerrain, updateTerrain:updateTerrain} = updateLOD();

                        console.log("newTerrain")
                        console.log(newTerrain)
                        console.log("updateTerrain")
                        console.log(updateTerrain)

                        if (terrainData.value.segments.length > 0) {

                            // load preloaded segments

                            worker.postMessage({
                                action: 'loadTerrainSegments',
                                request: newTerrain
                            });

                            worker.postMessage({
                                action: 'updateTerrainSegments',
                                request: updateTerrain
                            });

                        } else {

                            console.log("build terrain segment")
                            // generate segments
                            worker.postMessage({
                                action: 'buildTerrainGeometry',
                                request: newTerrain
                            });

                            worker.postMessage({
                                action: 'updateTerrainGeometry',
                                request: updateTerrain
                            });

                        }


                    }

                }

                // run some cleanup
                //console.log(performance.memory);
                //console.log("renderer.info.memory");
                //console.log(renderer.info.memory);
                renderer.renderLists.dispose();


                setTimeout(()=> {

                    trackCamera();

                }, 3000);

            },
            // Creates a worker to handle heavy updates
            startWorker = () => {

                worker.onmessage = (event) => {

                    var { action, response } = event.data;
                    activeSegments.value = [];

                    // assign the new buffer and rebuild geometry
                    if (action === "buildTerrainGeometryComplete") {

                        let vd = viewDistance.value + terrainData.value.lods[0];

                        const threshold = vd * vd;

                        for (var i in terrainNode.value.children) {

                            var id = terrainNode.value.children[i]?.userData?.terrainInfo?.coords;
                            var sharedAttributes = response[id];
                            if (sharedAttributes) {

                                buffers[id] = sharedAttributes;
                                geometryQueue[id] = {
                                    action: 'build',
                                    node: terrainNode.value.children[i]
                                }

                            }

                            const dx = terrainNode.value.children[i].position.x - camera.value.camera.position.x;
                            const dz = terrainNode.value.children[i].position.z - camera.value.camera.position.z;
                            const distSq = dx * dx + dz * dz;

                            // set which segments are visible and active
                            terrainNode.value.children[i].visible = distSq <= threshold;

                            if (terrainNode.value.children[i].visible === true) {

                                // mark as active (visible)
                                activeSegments.value.push(id);

                            } else {

                                // non active - cleanup
                                if (buffers[terrainNode.value.children[i].userData?.terrainInfo?.coords]?.position.length > 0) {

                                    // response[k].splatBitmap.close(); // free
                                    // response[k].normalBitmap.close(); // free

                                    terrainNode.value.children[i].geometry.dispose(0);
                                    terrainNode.value.children[i].material.dispose(0);

                                    worker.postMessage({
                                        action: 'clearBufferGeometry',
                                        request: terrainNode.value.children[i].userData?.terrainInfo?.coords
                                    });

                                }

                            }

                        }

                    }

                    // update geometry (buffer allready updated in worker)
                    if (action === 'updateTerrainGeometryComplete') {

                        for (var i in terrainNode.value.children) {

                            var id = terrainNode.value.children[i]?.userData?.terrainInfo?.coords;
                            if (response[id] === true && buffers[id]) {

                                geometryQueue[id] = {
                                    action: 'update',
                                    node: terrainNode.value.children[i]
                                }
                                // updateGeometry(buffers[id], terrainNode.value.children[i]);

                            }

                            //activeSegments.value.push(id);

                            if (terrainNode.value.children[i].visible === true) {

                                activeSegments.value.push(id);

                            }

                        }

                    }

                    if (action === 'clearBufferGeometryComplete') {

                        console.log("clearBufferGeometryComplete : delete buffer id " + response + " in main thread")
                        //console.log(buffers[response])

                    }

                    if (action === "buildSegmentDataComplete") {

                        var startTime = performance.now()

                        const halfMap = (terrainData.value.gridSize * terrainData.value.mapSize/2) - terrainData.value.gridSize / spg.value;
                        const centermap = new THREE.Vector3(halfMap, 0, halfMap);

                        for (var k=0; k<response.length; k++) {

                            const [i,j] = response[k].coords.split(',').map(Number);

                            // make sure we are not processing duplicates (TODO, work out flow for updating terrain)
                            if (mapsegs[`${i},${j}`]) {
                                continue;
                            }

                            response[k].lod = terrainData.value.lods.length - 1;
                            response[k].lods = terrainData.value.lods;
                            response[k].scale = terrainData.value.scale;
                            //response[k].position = terrainData.value.grid_.position;
                            //response[k].rotation = terrainData.value.grid_.rotation;

                            let sun = scene.getObjectByName('sun');
                            const worldSunPos = new THREE.Vector3();
                            const sunLight = sun.getObjectByName('sunLight');
                            sun.getWorldPosition(worldSunPos);

                            const viewDirection = new THREE.Vector3();
                            camera.value.camera.getWorldDirection(viewDirection);

                            const normalMap = new THREE.Texture(response[k].normalBitmap);
                            //const normalMap = textureLoader.load("public/textures/uvchecker.png");
                            normalMap.needsUpdate = true; 
                            normalMap.format = THREE.RGBAFormat;
                            normalMap.colorspace = THREE.NoColorSpace;
                            normalMap.anisotropy = renderer.capabilities.getMaxAnisotropy();

                            const splatMap = new THREE.Texture(response[k].splatBitmap);
                            splatMap.needsUpdate = true; 
                            //const splatMap = new THREE.CanvasTexture(response[k].splatBitmap);
                            splatMap.colorSpace = THREE.LinearSRGBColorSpace;
                            splatMap.minFilter = THREE.LinearMipMapLinearFilter;
                            splatMap.magFilter = THREE.LinearFilter;
                            splatMap.wrapT = THREE.RepeatWrapping;
                            splatMap.wrapS = THREE.RepeatWrapping;
                            splatMap.generateMipmaps = true;

                            //response[k].splatBitmap.close(); // free
                            //response[k].normalBitmap.close(); // free

                            const uniforms = {

                                noise: { value: noiseTexture },
                                normalMap: { value: normalMap },
                                splatMap: { value: splatMap },
                                diffuseMaps: { value: textureMaps[0] },
                                normalMaps: { value: textureMaps[1] },
                                roughnessMaps: { value: textureMaps[2] },
                                aoMaps: { value: textureMaps[3] },
                                // sunPosition: { value: sun.position.clone() },
                                sunPosition: { value: worldSunPos },
                                // heightScale: { value: terrainData.value.heightScale },
                                viewDirection: { value: viewDirection }

                            }

                            // setup base material
                            const customMaterial = new THREE.MeshStandardMaterial({
                                color: 0xaaaaaa, // base color
                                roughness: 1.0,
                                metalness: 0.0,
                                //emissiveIntensity: 0.1
                                //envMapIntensity: 0.1
                                //normalMap: normalMap
                            });

// NEWWAY
                            //const customMaterial = new THREE.MeshStandardMaterial({color: 0x999999});
                            //const customMaterial = new THREE.MeshLambertMaterial({color: 0x999999});

                            //const material = landscapeShader.build(customMaterial, uniforms);
                            // terrainMaterial.value = customMaterial;

                            const geometry = new THREE.BufferGeometry(),
                              segment = new THREE.Mesh( geometry, customMaterial );

                            //segment.position.set(0,0,pos)
                            //pos += 10;

                            segment.castShadow = true;
                            segment.receiveShadow = true;

                            // store easy access reference
                            mapsegs[`${i},${j}`] = segment;

                            // Add neighbor pointers (utilized for stitching edges)
                            if (j > 0) {

                                const prev = mapsegs[`${i},${j-1}`] 
                                if (prev) {
                                    response[k].neighbors.north = prev;
                                    prev.userData.terrainInfo.neighbors.south = segment;
                                }

                            }

                            if (i > 0) {

                                const prev_ = mapsegs[`${i-1},${j}`]; // - sViewSize
                                if (prev_) {
                                    response[k].neighbors.west = prev_;
                                    prev_.userData.terrainInfo.neighbors.east = segment;
                                }

                            }

                            segment.userData.terrainInfo = response[k];

                            const grid = terrainData.value.lods[0] * spg.value;

                            segment.position.set(response[k].x, 0, response[k].y);
                            segment.position.sub(centermap);

                            terrainNode.value.add(segment);

                        }

                        // set terrain position and rotation
                        terrainNode.value.position.set(
                          terrainData.value.position[0],
                          terrainData.value.position[1],
                          terrainData.value.position[2]);

                        terrainNode.value.rotation.set(
                          THREE.MathUtils.degToRad(terrainData.value.rotation[0]),
                          THREE.MathUtils.degToRad(terrainData.value.rotation[1]),
                          THREE.MathUtils.degToRad(terrainData.value.rotation[2]));

                        scene.add(toRaw(terrainNode.value));

                        trackCamera(true);

                        var endTime = performance.now()
                        console.log(`buildMap took ${endTime - startTime} milliseconds`)

                        inGridUpdate.value = false;

                    }

                    if (action === 'getHeightMapComplete') {

                        if (!heightMaps.value[`${response.gridX},${response.gridY}`]) {

                            // let width = 0, height = 0, data = [];  

                            heightMaps.value[`${response.gridX},${response.gridY}`] = response.heightMap;
                            // decimateMaps.value[`${response.gridX},${response.gridY}`] = response.decimateMap;

                        }

                        // build when all maps loaded
                        // if (Object.keys(heightMaps.value).length === terrainData.value.grids.length) {

                        let range = getGridRange();

                        if (hasAllHeightmaps(range) === true) {

                            buildMap(range.start, range.end);

                        } else {

                            // console.log('HEGHT MAPS NOT READY')

                        }

                    }

                    /*
                     * Get precomputed normals in addition to heights.
                     * Memory intensive, also calculation may not be very accurate
                     * Vertex calculation probably better.
                     */
                    if (action === 'getHeightNormalMapComplete') {

                        if (heightMaps.value[`${response.gridX},${response.gridY}`] === undefined) {

                            heightMaps.value[`${response.gridX},${response.gridY}`] = response.heightMap;
                            normalMaps.value[`${response.gridX},${response.gridY}`] = response.normalMap;

                        }

                        // build when all maps loaded
                        if (Object.keys(heightMaps.value).length === terrainData.value.grids.length) {

                            //buildMap();

                        }

                    }

                }

            },
            // build geometry 
            buildGeometry = async (attr, node, callback) => {

                const geometry = new THREE.BufferGeometry();
                geometry.setAttribute('position', new THREE.Float32BufferAttribute(attr.position, 3));
                geometry.setAttribute('normal', new THREE.Float32BufferAttribute(attr.normal, 3));
                geometry.setAttribute('tangent', new THREE.Float32BufferAttribute(attr.tangent, 3));
                geometry.setAttribute('bitangent', new THREE.Float32BufferAttribute(attr.bitangent, 3));
                geometry.setAttribute('uv', new THREE.Float32BufferAttribute(attr.uv, 2));
                geometry.setAttribute('uv2', new THREE.Float32BufferAttribute(attr.uv2, 2));
                geometry.setIndex(new THREE.BufferAttribute(attr.indices, 1));
                // geometry.setIndex(attr.indices); // breaks , i think required basic array
                geometry.computeVertexNormals(); // slow af , instead we do normal calc manually in worker
                geometry.attributes.position.needsUpdate = true;
                geometry.attributes.uv.needsUpdate = true;
                geometry.attributes.uv2.needsUpdate = true;
                geometry.attributes.normal.needsUpdate = true;
                geometry.attributes.tangent.needsUpdate = true;
                geometry.attributes.bitangent.needsUpdate = true;

                // if we use animation frame it seems to be fast but actually only one update per second..
                requestAnimationFrame(() => {
                });
                swapGeometry(node, toRaw(geometry))
                callback();

            },
            // update geometry in place
            updateGeometry = async (attr, node, callback) => {

    /*
                node.geometry.attributes.position.array.set(attr.position, 0);
                node.geometry.attributes.normal.array.set(attr.normal, 0);
                node.geometry.attributes.tangent.array.set(attr.tangent, 0);
                node.geometry.attributes.bitangent.array.set(attr.bitangent, 0);
                node.geometry.attributes.uv.array.set(attr.uv, 0);
                node.geometry.attributes.uv2.array.set(attr.uv2, 0);
                node.geometry.index.array.set(attr.indices, 0);
    */

                node.geometry.setAttribute('position', new THREE.Float32BufferAttribute(attr.position, 3));
                node.geometry.setAttribute('normal', new THREE.Float32BufferAttribute(attr.normal, 3));
                node.geometry.setAttribute('tangent', new THREE.Float32BufferAttribute(attr.tangent, 3));
                node.geometry.setAttribute('bitangent', new THREE.Float32BufferAttribute(attr.bitangent, 3));
                node.geometry.setAttribute('uv', new THREE.Float32BufferAttribute(attr.uv, 2));
                node.geometry.setAttribute('uv2', new THREE.Float32BufferAttribute(attr.uv2, 2));
                node.geometry.setIndex(new THREE.BufferAttribute(attr.indices, 1));

                // node.geometry.setIndex(attr.indices, 1);
                // node.geometry.setIndex(new THREE.BufferAttribute([], 1));

    /*
                node.geometry.attributes.uv.needsUpdate = true;
                node.geometry.attributes.uv2.needsUpdate = true;
                node.geometry.attributes.position.needsUpdate = true;
                node.geometry.attributes.normal.needsUpdate = true;
                node.geometry.attributes.tangent.needsUpdate = true;
                node.geometry.attributes.bitangent.needsUpdate = true;
                node.geometry.index.needsUpdate = true;
                //node.geometry.computeVertexNormals(); // slow af

    */
                callback();

            },
            swapGeometry = async (node, geometry) => {

                // clean up old geometry
                node.geometry.dispose();

                // Assign
                node.geometry = geometry;

                // Set Update TODO maybe push to worker also
                // node.geometry.attributes.position.needsUpdate = true;
                node.material.needsUpdate = true;

            },
            /*
             * coordinate updates in the main thread to minimize visual sheering
             * and manage render pipeline
             */
            processGeometryQueue = async () => {

                if (Object.keys(geometryQueue).length > 0 && inUpdate.value === false) {

                    inUpdate.value = true;

                    await mergeEdges();

                    for (var i in geometryQueue) {

                        if (geometryQueue[i].action === 'update') {

                            updateGeometry(buffers[i], geometryQueue[i].node, function() {

                                delete geometryQueue[i];

                            })

                        } else if  (geometryQueue[i].action === 'build') {

                            buildGeometry(buffers[i], geometryQueue[i].node, function() {

                                delete geometryQueue[i];

                            });

                        }

                    }
                    buildMacroMapMergeTexture();

                } else {

                    inUpdate.value = false;
                    
                    // final step is to fix the normal edges and update(notify) renderer

                }

                setTimeout(() => {

                    processGeometryQueue();

                }, 300)

            },
            mergeEdges = () => {

                for (let i = 0; i < terrainNode.value.children.length; i++) {

                    let terrainInfo = terrainNode.value.children[i].userData?.terrainInfo;
                    let eastNeighbor = terrainInfo.neighbors.east?.userData?.terrainInfo;

                    // remember that the actual used buffer length has a pad to allow for new entries.
                    // we need to compensate for this to add the edge data at the correct position.
                    const vertexPadLength = terrainData.value.lods[terrainInfo.lod] * 3;
                    const indexPadLength = terrainData.value.lods[terrainInfo.lod] * 2 * 3;

                    let currentBuffer = buffers[terrainInfo.coords];

                    if (!currentBuffer) continue;

                    // east-west seam
                    if (eastNeighbor && buffers[eastNeighbor.coords]) {

                        console.log("buffers[" + terrainInfo.coords + "]");
                        console.log(buffers[terrainInfo.coords])

                        let eastEdges = currentBuffer.eastEdges;
                        let westEdges = buffers[eastNeighbor.coords].westEdges;
                        let baseVPos = currentBuffer.position.length - vertexPadLength;
                        let baseIPos = currentBuffer.indices.length - indexPadLength;

                        if (eastEdges && westEdges) {

/*
                            for (var j=0; j < westEdges.length; j++) {

                                currentBuffer.position[baseVPos + j] = 
                                  buffers[eastNeighbor.coords].position[westEdges[j]];

                            }
*/


                            const endEdges = [];
                            for (let j = 0; j < westEdges.length; j += 3) {

                                const destIdx = baseVPos + j;
                                const srcX = westEdges[j];   // x
                                const srcZ = westEdges[j+1]; // z
                                const srcY = westEdges[j+2]; // y
                                currentBuffer.position[destIdx] = buffers[eastNeighbor.coords].position[srcX];
                                currentBuffer.position[destIdx + 1] = buffers[eastNeighbor.coords].position[srcZ];
                                currentBuffer.position[destIdx + 2] = buffers[eastNeighbor.coords].position[srcY];

/*
                                endEdges.push(destIdx);
                                endEdges.push(destIdx + 1);
                                endEdges.push(destIdx + 2);
*/

                            }

                            // currentBuffer.position[eastEdges[t]];
                           // currentBuffer.position[baseVPos] = 0.0;
                          //  currentBuffer.position[baseVPos+1] = 0.0;
                          //  currentBuffer.position[baseVPos+2] = 0.0;

                            console.log("eastEdges")
                            console.log(eastEdges)

                            //for (let j = 0; j < eastEdges.length - 3; j += 3) {
                            for (let j = 0; j < 3; j += 3) {
                                currentBuffer.indices[baseIPos + j] = eastEdges[j] / 3;
                                currentBuffer.indices[baseIPos + j + 1] = eastEdges[j + 3] / 3;
                                currentBuffer.indices[baseIPos + j + 2] = baseVPos / 3;
                            }

/*
                            var shorterEdge, longerEdge;

                            if (endEdges.length < eastEdges.length) {
                                shorterEdge = endEdges;
                                longerEdge = eastEdges;
                            } else {
                                shorterEdge = eastEdges;
                                longerEdge = endEdges;
                            }

                            const edgeRatio = shorterEdge.length / longerEdge.length;

                            let it = 0;
                            for (let j = 0; j < shorterEdge.length - 1; j++) {

                                let v = 0;
                                let n = 0;
                                while (v < 1) {

                                    currentBuffer.indices[baseIPos + it] = shorterEdge[j];
                                    currentBuffer.indices[baseIPos + it + 1] = shorterEdge[j+1]; 
                                    currentBuffer.indices[baseIPos + it + 2] = longerEdge[n];

                                    v+=edgeRatio;
                                    it += 3;
                                    n++;

                                }

                            }
*/
                        }

/*
                        // north south seam
                        if (southNeighbor && buffers[southNeighbor.coords]) {
                            let southEdges = currentBuffer.southEdges;
                            let northEdges = buffers[southNeighbor.coords].northEdges;

                            if (southEdges && northEdges) {
                                stitchEdges(currentBuffer, buffers[southNeighbor.coords], southEdges, northEdges);
                            }
                        }
*/

/*
                        for (var t in westEdges) {

                            buffers[eastNeighbor.coords].position[westEdges[t]] = 0;

                        }
                        for (var t in eastEdges) {

                            currentBuffer.position[eastEdges[t]] = 0;

                        }
*/
                    }

                }

            },
            stitchEdges = (currentBuffer, neighborBuffer, currentEdge, neighborEdge) => {
                

            },
            /*
             * This function runs at the end of the update pipeline, we loop over the whole map
             * and fix(average) the normals between neighbors. (makes it look seamless).
             */
            mergeEdgesOld = () => {

                // run over all children, if the node below or to the right of current is in the queue
                // then we average normals between their edges in place in the buffer.
                for (let i=0; i < terrainNode.value.children.length; i++) {

                    let terrainInfo = terrainNode.value.children[i].userData?.terrainInfo;
                    let east = terrainInfo.neighbors.east?.userData?.terrainInfo;
                    let south = terrainInfo.neighbors.south?.userData?.terrainInfo;

                    if (buffers[terrainInfo.coords]) {

                        if (buffers[east?.coords]) {

                            var mergeN, mergeT, mergeLod;

                            // low lod int are higher fidelity
                            if (terrainInfo.lod > east.lod) {

                                mergeN = 'half';
                                mergeT = 'full';

                            } else if (terrainInfo.lod < east.lod) {

                                mergeN = 'full';
                                mergeT = 'half';

                            } else {

                                mergeN = 'full';
                                mergeT = 'full';

                            }

                            // load edge vertices for fast picking 
                            var Tpairs = mergePairs[terrainInfo.lod];
                            var Npairs = mergePairs[east.lod];

                            for (var j=0; j < Tpairs.horizontal.current[mergeT].length; j+=3) {

                                let Navg = normalize([
                                    (buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j]]
                                    + buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j]])/2,
                                    (buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j+1]]
                                    + buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j+1]])/2,
                                    (buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j+2]]
                                    + buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j+2]])/2
                                ]);

                                let Tavg = normalize([
                                    (buffers[terrainInfo.coords].tangent[Tpairs.horizontal.current[mergeT][j]]
                                    + buffers[east.coords].tangent[Npairs.horizontal.neighbor[mergeN][j]])/2,
                                    (buffers[terrainInfo.coords].tangent[Tpairs.horizontal.current[mergeT][j+1]]
                                    + buffers[east.coords].tangent[Npairs.horizontal.neighbor[mergeN][j+1]])/2,
                                    (buffers[terrainInfo.coords].tangent[Tpairs.horizontal.current[mergeT][j+2]]
                                    + buffers[east.coords].tangent[Npairs.horizontal.neighbor[mergeN][j+2]])/2
                                ]);

                                let Bavg = normalize([
                                    (buffers[terrainInfo.coords].bitangent[Tpairs.horizontal.current[mergeT][j]]
                                    + buffers[east.coords].bitangent[Npairs.horizontal.neighbor[mergeN][j]])/2,
                                    (buffers[terrainInfo.coords].bitangent[Tpairs.horizontal.current[mergeT][j+1]]
                                    + buffers[east.coords].bitangent[Npairs.horizontal.neighbor[mergeN][j+1]])/2,
                                    (buffers[terrainInfo.coords].bitangent[Tpairs.horizontal.current[mergeT][j+2]]
                                    + buffers[east.coords].bitangent[Npairs.horizontal.neighbor[mergeN][j+2]])/2
                                ]);

                               // Navg[0] = -1;
                               // Navg[1] = -1;
                               // Navg[2] = -1;

                                // this node normal
                                buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j]] = Navg[0];
                                buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j+1]] = Navg[1];
                                buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j+2]] = Navg[2];

                                // east node normal
                                buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j]] = Navg[0];
                                buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j+1]] = Navg[1];
                                buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j+2]] = Navg[2]; 

                                // this node tangent
                                buffers[terrainInfo.coords].tangent[Tpairs.horizontal.current[mergeT][j]] = Tavg[0];
                                buffers[terrainInfo.coords].tangent[Tpairs.horizontal.current[mergeT][j+1]] = Tavg[1];
                                buffers[terrainInfo.coords].tangent[Tpairs.horizontal.current[mergeT][j+2]] = Tavg[2];

                                // east node tangent
                                buffers[east.coords].tangent[Npairs.horizontal.neighbor[mergeN][j]] = Tavg[0];
                                buffers[east.coords].tangent[Npairs.horizontal.neighbor[mergeN][j+1]] = Tavg[1];
                                buffers[east.coords].tangent[Npairs.horizontal.neighbor[mergeN][j+2]] = Tavg[2]; 

                                // this node bitangent
                                buffers[terrainInfo.coords].bitangent[Tpairs.horizontal.current[mergeT][j]] = Bavg[0];
                                buffers[terrainInfo.coords].bitangent[Tpairs.horizontal.current[mergeT][j+1]] = Bavg[1];
                                buffers[terrainInfo.coords].bitangent[Tpairs.horizontal.current[mergeT][j+2]] = Bavg[2];

                                // east node bitangent
                                buffers[east.coords].bitangent[Npairs.horizontal.neighbor[mergeN][j]] = Bavg[0];
                                buffers[east.coords].bitangent[Npairs.horizontal.neighbor[mergeN][j+1]] = Bavg[1];
                                buffers[east.coords].bitangent[Npairs.horizontal.neighbor[mergeN][j+2]] = Bavg[2]; 

    /*
                                // position , only necessary if smooting or other filters applied
                                buffers[terrainInfo.coords].position[Tpairs.horizontal.current[mergeT][j]] 
                                  = buffers[east.coords].position[Npairs.horizontal.neighbor[mergeN][j]];
                                buffers[terrainInfo.coords].position[Tpairs.horizontal.current[mergeT][j+1]] 
                                  = buffers[east.coords].position[Npairs.horizontal.neighbor[mergeN][j+1]];
                                buffers[terrainInfo.coords].position[Tpairs.horizontal.current[mergeT][j+2]] 
                                  = buffers[east.coords].position[Npairs.horizontal.neighbor[mergeN][j+2]];
    */

    /*
                                let c = [
                                    buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j]],
                                    buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j+1]],
                                    buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j+2]],
                                ]

                                let n = [
                                    buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j]],
                                    buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j+1]],
                                    buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j+2]],
                                ]

                                const m1 = magnitude(c);
                                const m2 = magnitude(n);

                                const w1 = m1 / (m1+m2);
                                const w2 = m2 / (m1+m2);

                                const avg = normalize([
                                    c[0] * w1 + n[0] * w2,
                                    c[1] * w1 + n[1] * w2,
                                    c[2] * w1 + n[2] * w2
                                ]);

                                // this node
                                buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j]] = avg[0];
                                buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j+1]] = avg[1];
                                buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j+2]] = avg[2];

                                // east node
                                buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j]] = avg[0];
                                buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j+1]] = avg[1];
                                buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j+2]] = avg[2];
    */
                            }

                        }

                    }

                }

                for (let i=0; i < terrainNode.value.children.length; i++) {

                    let terrainInfo = terrainNode.value.children[i].userData?.terrainInfo;
                    let east = terrainInfo.neighbors.east?.userData?.terrainInfo;
                    let south = terrainInfo.neighbors.south?.userData?.terrainInfo;

                    if (buffers[terrainInfo.coords]) {

                        if (buffers[south?.coords]) {

                            // low lod int are higher fidelity
                            if (terrainInfo.lod > south.lod) {

                                mergeN = 'half';
                                mergeT = 'full';

                            } else if (terrainInfo.lod < south.lod) {

                                mergeN = 'full';
                                mergeT = 'half';

                            } else {

                                mergeN = 'full';
                                mergeT = 'full';

                            }

                            // load edge vertices for fast picking 
                            var Tpairs = mergePairs[terrainInfo.lod];
                            var Npairs = mergePairs[south.lod];

                            // stitch segments
                            for (var j=0; j < Tpairs.vertical.current[mergeT].length; j+=3) {

                                // this is unused, i tried several ways to blend, seems difficult to get without seams
                                let Navg = normalize([
                                    (buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j]]
                                    + buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j]])/2,
                                    (buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+1]]
                                    + buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+1]])/2,
                                    (buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+2]]
                                    + buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+2]])/2
                                ]);

                                // this node
                                buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j]] = Navg[0];
                                buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+1]] = Navg[1];
                                buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+2]] = Navg[2];

                                // south node
                                buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j]] = Navg[0];
                                buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+1]] = Navg[1];
                                buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+2]] = Navg[2];

    /*
                                // second way, maybe a little better?
                                let c = [
                                  buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j]],
                                  buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+1]],
                                  buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+2]]
                                ]

                                let n = [
                                  buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j]],
                                  buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+1]],
                                  buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+2]]
                                ]

                                const m1 = magnitude(c); // sqrt of sum of each component squared
                                const m2 = magnitude(n); //

                                const w1 = m1 / (m1+m2);
                                const w2 = m2 / (m1+m2);

                                const avg = normalize([
                                    c[0] * w2 + n[0] * w1,
                                    c[1] * w2 + n[1] * w1,
                                    c[2] * w2 + n[2] * w1
                                ]);

                                // this node
                                buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j]] = avg[0];
                                buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+1]] = avg[1];
                                buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+2]] = avg[2];

                                // east node
                                buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j]] = avg[0];
                                buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+1]] = avg[1];
                                buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+2]] = avg[2];
    */
    /*
                                // position , only necessary if smooting or other filters applied
                                buffers[terrainInfo.coords].position[Tpairs.horizontal.current[mergeT][j]] 
                                  = buffers[east.coords].position[Npairs.horizontal.neighbor[mergeN][j]];
                                buffers[terrainInfo.coords].position[Tpairs.horizontal.current[mergeT][j+1]] 
                                  = buffers[east.coords].position[Npairs.horizontal.neighbor[mergeN][j+1]];
                                buffers[terrainInfo.coords].position[Tpairs.horizontal.current[mergeT][j+2]] 

                                // this node

                                // south node
                                buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j]]
                                  = buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j]];

                                buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+1]]
                                  = buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+1]];

                                buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+2]]
                                  = buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+2]];

    */
    /*
                                // this node
                                buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j]] = Navg[0];
                                buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+1]] = Navg[1];
                                buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+2]] = Navg[2];

                                // south node
                                buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j]] = Navg[0];
                                buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+1]] = Navg[1];
                                buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+2]] = Navg[2];
    */
    /*

                                // position
                                if ((j+1)%3 === 0) {

                                    buffers[south.coords].position[Npairs.vertical.neighbor[mergeN][j]]
                                      = buffers[terrainInfo.coords].position[Tpairs.vertical.current[mergeT][j]];
                                    
                                }
    */

                            }

                        }

                     }

                  }

              },
              hasAllHeightmaps = (range) => {

                  const { start, end } = range;
                  const [startX, startY] = start;
                  const [endX, endY] = end;

                  for (let x = startX; x <= endX; x++) {
                      for (let y = endY; y <= startY; y++) {
                          const key = `${x},${y}`;
                          if (!heightMaps.value[key]) {
                              return false;
                          }
                      }
                  }

                  return true;
              },
              getGridData = () => {

                  // calculate the grids per segment
                  spg.value = terrainData.value.gridSize / terrainData.value.lods[0];

                  // calculate the start of the grid
                  gridStartX.value = Infinity;
                  gridStartY.value = Infinity;

                  for (var r=0; r< terrainData.value.grids.length; r++) {

                      gridStartX.value = gridStartX.value < terrainData.value.grids[r].id[0] ? gridStartX.value : terrainData.value.grids[r].id[0];
                      gridStartY.value = gridStartY.value < terrainData.value.grids[r].id[1] ? gridStartY.value : terrainData.value.grids[r].id[1];

                  }

              },
              getCurrentGrid = () => {

                  const gridSize = terrainData.value.gridSize;
                  const mapSize = terrainData.value.mapSize * gridSize;
                  const cameraX = camera.value.camera.position.x;
                  const cameraZ = camera.value.camera.position.z;

                  // Adjust grid for center start map
                  const adjustedX = cameraX + mapSize / 2;
                  const adjustedZ = cameraZ + mapSize / 2;

                  const xOffset = Math.floor(adjustedX / gridSize);
                  const yOffset = Math.floor(adjustedZ / gridSize);

                  if (!isNaN(xOffset) && !isNaN(yOffset)) {

                      return [xOffset, yOffset];

                  } else {

                      return;

                  }

              },
              getCurrentCoords = () => {

                  const _grid = getCurrentGrid();

                  const l = terrainData.value.lods[0] * terrainData.value.scale; // length/width of segment
                  var xOffset = Math.floor(camera.value.camera.position.x / l);
                  var yOffset = Math.floor(camera.value.camera.position.z / l);

                  xOffset = _grid[0] * spg.value + xOffset;
                  yOffset = _grid[1] * spg.value + yOffset;

                  return [xOffset,yOffset];

              },
              getGridRange = () => {

                  const thisGrid = getCurrentGrid();
                  const gridScope = Math.ceil(viewDistance.value / ((terrainData.value.gridSize/2) * 3));

                  const start = [thisGrid[0] - gridScope, thisGrid[1] + gridScope];
                  const end = [thisGrid[0] + gridScope, thisGrid[1] - gridScope];
                  return {start,end}

              },
              // TODO: write geometry to disk
              normalize = ([a,b,c]) => {

                  let length = Math.sqrt( a*a + b*b + c*c );

                  if (length === 0) 
                    return [0, 0, 0];

                  return [a / length, b / length, c / length];

              },
              magnitude = ([a,b,c]) => {

                  return Math.sqrt( a*a + b*b + c*c );

              },
              toggleWireframe = () => {

                  terrainNode.value.traverse((n) => {

                      if (n.material) {

                          if (n.material.wireframe == true) {

                              n.material.wireframe = false;
           
                          } else {

                              n.material.wireframe = true;
           
                          }

                      }

                  });

              },
              // uses fast-png (supports 16bit greyscale)
              loadHeightMap = (imagePath) => {

                  return new Promise(async (resolve, reject) => {

                      try {

console.log("123")
                          const response = await axios.get(imagePath, { responseType: 'arraybuffer' });
                          const arrayBuffer = response.data;

                          const imageData = await decode(arrayBuffer);
                          //const imageData = jpeg.decode(new Uint8Array(arrayBuffer), { useTArray: true });
                          const { width, height, data } = imageData;

                          resolve({ width, height, data });

                      } catch (error) {

                          reject(error);

                      }

                  });

              },
              loadImage = (imagePath) => {

                  return new Promise((resolve, reject) => {

                    const image = new Image();

                    image.onload = () => {

                        const width = image.width;
                        const height = image.height;

                        const canvas = document.createElement('canvas');
                        canvas.width = width;
                        canvas.height = height;
                        const context = canvas.getContext('2d');
                        context.drawImage(image, 0, 0);

                        const imageData = context.getImageData(0, 0, width, height);
                        const data = imageData.data;

                        const heights = [];

                        resolve({width, height, data});

                    };

                    image.onerror = function() {
                
                        console.error('Failed to load the image at ' + imagePath);
                        reject();

                    };

                    image.src = imagePath;

                  })
              },
              // The supplied images from generate image have an extra duplicate pixel
              // on the right(east) and bottom(south) for consistent merge
              calculateMergePairs = () => {

                  for (var lod in terrainData.value.lods) {

                      const pairs = { // pre-calculated lod neighbor segment vertex pairs for merging normals

                          // comparison of a node to it's neighbor to the east
                          horizontal: {
                              current: { // the current segment
                                full: [], // every vertex for lod
                                half: [] // every other vertex for lod
                              }, 
                              neighbor: { // the neighbor segment (right)
                                full: [], // every vertex for lod
                                half: [] // every other vertex for lod
                              }
                          },
                          vertical: {
                              current: { // the current segment
                                full: [], // every vertex for lod
                                half: [] // every other vertex for lod
                              }, 
                              neighbor: { // the neighbor segment (below)
                                full: [], // every vertex for lod
                                half: [] // every other vertex for lod
                              }
                          }

                      }; 

                      var verts = terrainData.value.lods[lod] + 1;

                      var f = 0;
                      // this (current) segment 
                      for (var j=verts - 1; j < verts*verts; j+=verts) {

                          pairs.horizontal.current.full.push(j*3);
                          pairs.horizontal.current.full.push(j*3+1);
                          pairs.horizontal.current.full.push(j*3+2);

                          if ((f/3) % 2 === 0) {

                              pairs.horizontal.current.half.push(j*3);
                              pairs.horizontal.current.half.push(j*3+1);
                              pairs.horizontal.current.half.push(j*3+2);

                          }

                          f+=3;

                      }

                      f = 0;
                      // neighbor (east) segment
                      for (var j=0; j < verts*verts; j+=verts) {

                          pairs.horizontal.neighbor.full.push(j*3);
                          pairs.horizontal.neighbor.full.push(j*3+1);
                          pairs.horizontal.neighbor.full.push(j*3+2);

                          if ((f/3) % 2 === 0) {

                              pairs.horizontal.neighbor.half.push(j*3);
                              pairs.horizontal.neighbor.half.push(j*3+1);
                              pairs.horizontal.neighbor.half.push(j*3+2);

                          }

                          f+=3;

                      }

                      f = 0;
                      // current
                      for (var i = verts * verts - verts; i < verts * verts; i++) {

                          pairs.vertical.current.full.push(i*3);
                          pairs.vertical.current.full.push(i*3+1);
                          pairs.vertical.current.full.push(i*3+2);

                          if ((f / 3) % 2 === 0) {

                              pairs.vertical.current.half.push(i*3);
                              pairs.vertical.current.half.push(i*3+1);
                              pairs.vertical.current.half.push(i*3+2);

                          }
                          f += 3;

                      }

                      f = 0;
                      // this one is the problem *** FIX
                      // neighbor (south) segment
                      for (var i = 0; i < verts; i++) {

                          pairs.vertical.neighbor.full.push(i*3);
                          pairs.vertical.neighbor.full.push(i*3+1);
                          pairs.vertical.neighbor.full.push(i*3+2);

                          if ((f / 3) % 2 === 0) {

                              pairs.vertical.neighbor.half.push(i*3);
                              pairs.vertical.neighbor.half.push(i*3+1);
                              pairs.vertical.neighbor.half.push(i*3+2);

                          }
                          f += 3;

                      }

    /*
                      f = 0;
                      // this (current) segment 
                      for (var i = 0; i < verts; i++) {

                          pairs.vertical.current.full.push(i * 3);
                          pairs.vertical.current.full.push(i * 3 + 1);
                          pairs.vertical.current.full.push(i * 3 + 2);

                          if ((f / 3) % 2 === 0) {

                              pairs.vertical.current.half.push(i * 3);
                              pairs.vertical.current.half.push(i * 3 + 1);
                              pairs.vertical.current.half.push(i * 3 + 2);

                          }

                          f += 3;

                      }

                      f = 0;
                      // Neighbor (north) segment
                      for (var i = verts * verts - verts; i < verts * verts; i++) {

                          pairs.vertical.neighbor.full.push(i * 3);
                          pairs.vertical.neighbor.full.push(i * 3 + 1);
                          pairs.vertical.neighbor.full.push(i * 3 + 2);

                          if ((f / 3) % 2 === 0) {

                              pairs.vertical.neighbor.half.push(i * 3);
                              pairs.vertical.neighbor.half.push(i * 3 + 1);
                              pairs.vertical.neighbor.half.push(i * 3 + 2);

                          }

                          f += 3;

                      }
    */
                      mergePairs[lod] = pairs;

                }

            },
            process = () => {
            },
            buildMacroMap = () => {

                const textures = {
                    heightMap: {
                        image: terrainData.value.miniHeightMap,
                        filter: {
                          min: THREE.LinearFilter,
                          mag: THREE.LinearFilter
                        },
                        type: THREE.FloatType,
                        encoding: THREE.LinearEncoding
                    },
                    normalMap: {
                        image: terrainData.value.miniNormalMap,
                        filter: { min: THREE.LinearMipMapLinearFilter, mag: THREE.LinearFilter },
                        colorSpace: THREE.LinearSRGBColorSpace
                    },
                    splatMap: {
                        image: terrainData.value.miniSplatMap,
                        filter: { min: THREE.LinearMipMapLinearFilter, mag: THREE.LinearFilter },
                        colorSpace: THREE.LinearSRGBColorSpace
                    },
                    flowMap: {
                        image: terrainData.value.miniFlowMap,
                        filter: { min: THREE.LinearMipMapLinearFilter, mag: THREE.LinearFilter },
                        colorSpace: THREE.LinearSRGBColorSpace
                    }
                };

                const loadTexture = (data = {}) => {

                    return new Promise((resolve, reject) => {

                        textureLoader.load(
                            data.image,
                            (texture) => {
                                if (data.filter) {
                                    texture.minFilter = data.filter.min;
                                    texture.magFilter = data.filter.mag;
                                }
                                if (data.colorSpace) {
                                    texture.colorSpace = data.colorSpace;
                                }
                                if (data.type) {
                                    texture.type = data.type;
                                }
                                if (data.encoding) {
                                    texture.encoding = data.encoding;
                                }
                                resolve(texture);
                            },
                            undefined,  // No progress handler needed here
                            (error) => reject(error)  // Reject on error
                        );

                    });

                };

                Promise.all([
                    loadHeightMap(textures.heightMap.image), // why twice ? well we need the array for martini, maybe a better way
                    loadTexture(textures.heightMap),
                    loadTexture(textures.normalMap),
                    loadTexture(textures.splatMap)
                ]).then(([heightPng, heightMap, normalMap, splatMap]) => {

                    const lodsize = terrainData.value.lods[0];
                    const mapsize = terrainData.value.mapSize * terrainData.value.gridSize;
                    //const geometry = new THREE.PlaneGeometry(mapsize, mapsize, lodsize, lodsize);


                    const geometry = getMartiniGeometry(heightPng, [mapsize,mapsize]);

//                  const vertices = new Float32Array(tile.martini.coords);
//                  const indices = new Uint32Array(tile.martini.indices);
//
//                  geometry.setAttribute("position", new THREE.Float32BufferAttribute(mesh.vertices, 3));
//                  geometry.setIndex(new THREE.BufferAttribute(indices, 1));

console.log("geometry")
console.log(geometry)

                    //const indices = updateCutout(lodsize, activeSegments.value);
                    //geometry.setIndex(indices);

                    const noiseTexture = textureLoader.load("public/textures/noise.jpg");
                    noiseTexture.colorSpace = THREE.LinearSRGBColorSpace;
                    noiseTexture.minFilter = THREE.LinearMipMapLinearFilter;
                    noiseTexture.magFilter = THREE.LinearFilter;
                    noiseTexture.wrapT = THREE.RepeatWrapping;
                    noiseTexture.wrapS = THREE.RepeatWrapping;
                    noiseTexture.generateMipmaps = true;

                    const rockTexture = textureLoader.load("public/textures/stylized_cliff_1_diffuse.jpg");
                    rockTexture.colorSpace = THREE.LinearSRGBColorSpace;
                    rockTexture.minFilter = THREE.LinearMipMapLinearFilter;
                    rockTexture.magFilter = THREE.LinearFilter;
                    rockTexture.wrapT = THREE.RepeatWrapping;
                    rockTexture.wrapS = THREE.RepeatWrapping;
                    rockTexture.generateMipmaps = true;

                    let sun = scene.getObjectByName('sun');
                    const worldSunPos = new THREE.Vector3();
                    const sunLight = sun.getObjectByName('sunLight');
                    sun.getWorldPosition(worldSunPos);

                    const viewDirection = new THREE.Vector3();
                    camera.value.camera.getWorldDirection(viewDirection);

                    // normalMap.flipY = true;
                    // const macroMapSize = terrainData.value.minimapSize;
                    const macroMapSize = (terrainData.value.gridSize / terrainData.value.lods[0]) * terrainData.value.mapSize;
                    mergeTextureData.value = new Uint8Array( 4 * macroMapSize * macroMapSize );
                    mergeTexture.value = new THREE.DataTexture(mergeTextureData.value, macroMapSize, macroMapSize, THREE.RGBAFormat);

                    const uniforms = {

                        noise: { value: noiseTexture },
                        normalMap: { value: normalMap },
                        splatMap: { value: normalMap },
                        rockTexture: { value: rockTexture },
                        sunPosition: { value: worldSunPos },
                        mergeDistance: { value: viewDistance.value },
                        mergeTexture: { value: mergeTexture.value },
                        macroMapSize: { value: macroMapSize.value },
                        viewDirection: { value: viewDirection }

                    }

                    const customMaterial = new THREE.MeshStandardMaterial({
                        color: 0xaaaaaa,
                        roughness: 1.0,
                        metalness: 0.0,
                        displacementMap: heightMap,
                        //wireframe: true,
                        //normalMap: normalMap,
                        displacementScale: terrainData.value.minimapHeightScale
                    });

                    const custom_ = macroLandscapeShader.build(customMaterial, uniforms);

                    geometry.computeVertexNormals();
                    const macroTerrain = new THREE.Mesh(geometry, customMaterial);
                    macroTerrain.rotation.x = -Math.PI / 2;
                    macroTerrain.position.set(0, terrainData.value.minimapHeightOffset, 0);
                    macroTerrain.name = "macroTerrain";
                    scene.add(macroTerrain);

                    buildMacroMapMergeTexture();

                });

            },
            getMartiniGeometry = (heightData, size) => {

                const martini = new Martini(heightData.width);
                const tile = martini.createTile(heightData.data);
                  
console.log("heightData.width")
console.log(heightData.width)
console.log("heightData.data")
console.log(heightData.data)
console.log("tile")
console.log(tile)

                const mesh = tile.getMesh(10);

console.log("mesh")
console.log(mesh)

                var v = mesh.vertices.length;
                var mv = mesh.vertices.length;
              
                const vertices = new Float32Array((mv / 2) * 3);
                const uv = new Float32Array(mv);
                const tileSize = heightData.width;

                for (var i = 0; i < mesh.vertices.length / 2; i++) {

                    let x = mesh.vertices[i * 2],
                      y = mesh.vertices[i * 2 + 1];

                    // const heightIndex = Math.floor(y) * tileSize + Math.floor(x);

                    vertices[3 * i + 0] = (x - tileSize/2) / tileSize * size[0];
                    vertices[3 * i + 1] = (y - tileSize/2) / tileSize * size[1];
                    vertices[3 * i + 2] = 0;
                    //vertices[3 * i + 2] = heightData.data[heightIndex] / 65535.0 * size[2];

                    uv[2 * i + 0] = x / tileSize;
                    uv[2 * i + 1] = y / tileSize;

                }

                const geometry = new THREE.BufferGeometry();
                geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
                geometry.setAttribute('uv', new THREE.BufferAttribute(uv, 2));

                // Ensure the index is correctly set for the triangles
                geometry.setIndex(new THREE.BufferAttribute(new Uint32Array(mesh.triangles.reverse()), 1));

                return geometry;

            },
            updateMacroMap = () => {

                var startTime = performance.now()

                const lodsize = terrainData.value.lods[0];
                const mapsize = terrainData.value.mapSize * terrainData.value.gridSize;

                let macroTerrain = scene.getObjectByName("macroTerrain");
                let displacementTexture = macroTerrain.material.displacementMap;

                const indices = updateCutout(lodsize, activeSegments.value);

                macroTerrain.geometry.setIndex(indices);
                macroTerrain.material.needsUpdate = true;
                macroTerrain.geometry.attributes.position.needsUpdate = true;

    /*
                const lodsize = terrainData.value.lods[0];
                const mapsize = terrainData.value.mapSize * terrainData.value.gridSize;

                let macroTerrain = scene.getObjectByName("macroTerrain");
                let displacementTexture = macroTerrain.material.displacementMap;

                const geometry = updateCutout(macroTerrain.geometry, lodsize, activeSegments.value);

                //macroTerrain.geometry.dispose();
                //macroTerrain.geometry = geometry;
                macroTerrain.material.needsUpdate = true;
    */
            },
            buildMacroMapMergeTexture = function() {

                //const texSize = terrainData.value.minimapSize;
                const texSize = (terrainData.value.gridSize / terrainData.value.lods[0]) * terrainData.value.mapSize;
                const noSegments = (terrainData.value.mapSize * terrainData.value.gridSize) / terrainData.value.lods[0];
                const segmentSize = texSize / noSegments;

                mergeTextureData.value.fill(0);

                for (const c of activeSegments.value) {

                    const [sx,sy] = c.split(',').map(Number);

                    const pxStart = sx * segmentSize;
                    const pxEnd = pxStart + segmentSize;

                    const pyStart = (noSegments - 1 - sy) * segmentSize;
                    //const pyStart = sy * segmentSize;
                    const pyEnd = pyStart + segmentSize;

                    for (let x = pxStart; x < pxEnd; x++) {

                        for (let y = pyStart; y < pyEnd; y++) {

                            const index = (y * texSize + x) * 4;
                            mergeTextureData.value[index] = 255;
                            mergeTextureData.value[index + 1] = 0;
                            mergeTextureData.value[index + 2] = 0;
                            mergeTextureData.value[index + 3] = 255;

                        }

                    }

                }

                // Create a WebGL texture (assuming three.js)
                mergeTexture.value.needsUpdate = true;
                let macroTerrain = scene.getObjectByName("macroTerrain");
                macroTerrain.material.needsUpdate = true;
                console.log("end buildMacroMapMergeTexture")
                
            },
            createPlaneGeometry = (width, height, segments) => {

                const geometry = new THREE.BufferGeometry();

                // Generate vertices and UVs for the entire grid
                const vertices = [];
                const uvs = [];
                const normals = [];

                const segmentWidth = width / segments;
                const segmentHeight = height / segments;

                for (let i = 0; i <= segments; i++) {
                    for (let j = 0; j <= segments; j++) {

                        const x = i * segmentWidth - width / 2;
                        const y = j * segmentHeight - height / 2;
                        vertices.push(x, 0, y);
                        uvs.push(i / segments, 1 - j / segments);
                        normals.push(0, 1, 0);

                    }
                }

                // Set initial attributes
                geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
                geometry.setAttribute('uv', new THREE.Float32BufferAttribute(uvs, 2));
                geometry.setAttribute('normal', new THREE.Float32BufferAttribute(normals, 3));

                return geometry;
            },
            updateCutout = (segments, cutouts) => {

                function isQuadInCutout(i, j, cutoutSet, stepSize) {

                    const blockXStart = Math.floor(i / stepSize);
                    const blockYStart = Math.floor(j / stepSize);

                    const blockXEnd = Math.min(Math.floor(i / stepSize), Math.floor(segments / stepSize) - 2);
                    const blockYEnd = Math.min(Math.floor(j / stepSize), Math.floor(segments / stepSize) - 2);

                    for (let x = blockXStart; x <= blockXEnd; x++) {

                        for (let y = blockYStart; y <= blockYEnd; y++) {

                            if (cutoutSet.has(`${x},${y}`)) {

                                return true;

                            }

                        }

                    }

                }

                const width_ = terrainData.value.gridSize * terrainData.value.mapSize;
                const stepSize = 8;
                const cutoutSet = new Set(cutouts);
                const indices = [];

                for (let i = 0; i < segments-1; i++) {

                    for (let j = 0; j < segments-1; j++) {

                        if (!isQuadInCutout(j,i, cutoutSet, stepSize)) {

                            const a = i * (segments + 1) + j;
                            const b = a + 1;
                            const c = a + (segments + 1);
                            const d = c + 1;

                            indices.push(a, d, b);
                            indices.push(a, c, d);

                        }

                    }

                }

                return indices;

            },
            showStats = () => {
                setTimeout(()=>{
                    showStats();
                }, 5000);
            };

            onMounted(() => {

                // todo: make sure this is origin, now we assume it's the center of the json grid

                loadTerrain();
                startWorker();
                processGeometryQueue();

                // set inital camera position
                //let x = currentPosition.value[0] * terrainData.value.gridSize;
                //let z = currentPosition.value[1] * terrainData.value.gridSize;
                //camera.value.camera.position = THREE.Vector3(x,0,z);
                trackPosition.copy(camera.value.camera.position);

                watch(
                    () => keyPress.value,

                    (first, second) => {

                        if (keyPress.value.o == true) {

                            toggleWireframe();

                        }

                        if (keyPress.value.arrowup == true) {
                            camera.value.camera = Math.max(10, camera.value.camera.fov - 1);
                            camera.value.camera.updateProjectionMatrix(); // Apply changes
                        }

                        if (keyPress.value.arrowdown == true) {

                            camera.value.camera = Math.min(120, camera.value.camera.fov + 1);
                            camera.value.camera.updateProjectionMatrix(); // Apply changes

                        }

                    },
                { deep: true });

            });

            onUnmounted(() => {
                worker.terminate();
                
                if (rootNode.value) {
                    // rootNode.value.removeChild(renderer.domElement);
                }
            });

        return {
            scene,
            renderer,
            axios,
            rootNode,
            camera,
            trackPosition,
            trackCamera,
            updateDelta,
            debounceKey,
            loadTerrain,
            loadMaps,
            buildMap,
            updateLOD,
            terrainNode,
            buildMacroMap,
            updateMacroMap,
            cacheMap,
            terrainData,
            keyPress,
            toggleWireframe,
            worker,
            startWorker,
            buildGeometry,
            updateGeometry,
            processGeometryQueue,
            hasAllHeightmaps,
            setCameraPosition,
            loadImage,
            swapGeometry,
            terrainMaterial,
            mergeEdges,
            stitchEdges,
            mergeEdgesOld,
            mergePairs,
            getCurrentCoords,
            getCurrentGrid,
            getGridRange,
            calculateMergePairs,
            textureLoader,
            normalize,
            magnitude,
            geometryQueue,
            heightMaps,
            normalMaps,
            decimateMaps,
            textureMaps,
            noiseTexture,
            loadTextures,
            loader,
            scale,
            currentGrid,
            getGridData,
            gridStartX,
            gridStartY,
            inGridUpdate,
            viewDistance,
            activeLights,
            activeSegments,
            buffers,
            inUpdate,
            process,
            mapsegs,
            updateCutout,
            createPlaneGeometry,
            buildMacroMapMergeTexture,
            mergeTexture,
            mergeTextureData,
            getMartiniGeometry,
            showStats
        };

    }

};

</script>

<style scoped>
div {
width: 100%;
height: 100%;
}
</style>
