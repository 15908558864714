<template>
  <div
    ref="rootNode"
  >
    <div class="overlay" />
    <div ref="three" />
    <Controls
      ref="controlComponent"
      @key-press="captureKeyPress"
      @mouse-info="captureMouseInfo"
    />
    <Camera ref="camera" />
    <Entity
      ref="entityComponent"
      @add-entity="addEntity"
      @load-entity="loadEntity"
      @create-rigid-body="createRigidBody"
    />
    <Actions
      ref="actionsComponent"
    />
    <Physics
      v-if="physicsLoaded == true"
      ref="physicsComponent"
      :entities="entities"
      @add-to-scene="addToScene"
    />
    <Terrain
      v-if="terrainLoaded == true"
      ref="terrainComponent"
    />
    <CardGame
      v-if="sceneLoaded == true"
      ref="cardGameComponent"
      @add-to-scene="addToScene"
    />
    <Composer
      v-if="sceneLoaded == true"
      ref="composerComponent"
      :scene-loaded="sceneLoaded"
    />
    <Transitions
      v-if="sceneLoaded == true"
      ref="transitionsComponent"
    />
    <Transition>
      <CModal
        v-if="showInsertModal"
        color="#5d328d"
        width="small"
        secondaryColor="linear-gradient(338deg,#ff7cf5,#46b6ff)"
        @close="showInsertModal = false"
      >
        <template #header>
          <div> {{ localize("TEXT_INSERT") }} </div>
        </template>
        <template #body>
          <div>
            <div v-for="(v,k) in entities">
              <div @click="loadAsset(k)">
                 {{k}}
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <div />
        </template>
      </CModal>
    </Transition>
  </div>
</template>

<script>
import * as THREE from 'three';
//import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { MeshoptDecoder } from 'three/addons/libs/meshopt_decoder.module.js';
import { inject, nextTick, onBeforeUnmount, onMounted, provide, ref, toRaw, watch } from 'vue';
//import * as AmmoJS from 'ammo.js';
import * as AmmoJS from 'ammojs3';
import { utils } from '@/utils/utils';
import { gameStore } from "@/stores/game";
import Stats from 'stats.js';
import Entity from './Entity.vue';
import Controls from './Controls.vue';
import Camera from './Camera.vue';
import Physics from './Physics.vue';
import Composer from './Composer.vue';
import Actions from './Actions.vue';
import CardGame from './CardGame.vue';
import backgroundSkybox from '../../public/textures/kyoto-skybox-1.jpg';
import backgroundSkyboxDepth from '../../public/textures/kyoto-skybox-1-depth.jpg';
import Terrain from './Terrain.vue';
import Transitions from './Transitions.vue';
import { UltraHDRLoader } from 'three/addons/loaders/UltraHDRLoader.js';
import { GainMapLoader } from '@monogrid/gainmap-js'
import {translationStore} from "@/stores/translation";

export default {
  name: 'Scene',
  "components": {
      Controls,
      Camera,
      Actions,
      Physics,
      Composer,
      CardGame,
      Entity,
      Terrain,
      Transitions
  },
  setup() {

    const rootNode = ref(),
    translation = translationStore(),
    axios = inject('Axios'),
    fpsstats = ref(new Stats()),
    memstats = ref(new Stats()),
    showInsertModal = ref(false),
    deltaX = ref(0),
    deltaY = ref(0),
    currentX = ref(0),
    currentY = ref(0),
    keyPress = ref({}),
    ammoLib = ref({}),
    selectedEntity = ref(),
    mouseHeld = ref(false),
    sunDistance = ref(10000),
    pointerGeom = new THREE.SphereGeometry( 2, 22, 12 ),
    pointerMat = new THREE.MeshBasicMaterial( { color: 0xff0000 } ),
    pointer = new THREE.Mesh( pointerGeom, pointerMat ),
    sunGeom = new THREE.SphereGeometry( 100, 32, 16 ),
    sunMat = new THREE.MeshBasicMaterial( { color: 0xffffff } ),
    sun = new THREE.Mesh( sunGeom, sunMat ),
    sunAngle = ref(0),
    latitude = ref(THREE.MathUtils.degToRad(36)), // Japan lat
    debounceKey = ref({}),
    entities = ref({
        'AsianTemple.glb': {},
        'large-sakura.glb': {},
        'shrine-gate.glb': {},
        'stylized-rock-A.glb': {},
        'sakura-tree.glb': {},
        'shrine-monument.glb': {},
        'temple-A.glb': {}
    }),
    mouseInfo = ref({
      clientX:0,
      clientY:0,
      deltaX:0,
      deltaY:0,
      dragDeltaX:0,
      dragDeltaY:0,
      mouseDown:false,
      mouseUp:false,
      buttons:0
    }),
    game = gameStore(),
    scene = new THREE.Scene(),
    // renderer = new THREE.WebGLRenderer( { alpha: true } ),
    renderer = new THREE.WebGLRenderer( { } ),
    clock = new THREE.Clock(),
    axesHelper = new THREE.AxesHelper( 5 ),
    gridHelper = new THREE.GridHelper( 15, 15),
    time = ref(0),
    three = ref(null),
    camera = ref(null),
    terrainComponent = ref(null),
    cardGameComponent = ref(null),
    controlComponent = ref(null),
    entityComponent = ref(null),
    actionsComponent = ref(null),
    physicsComponent = ref(null),
    composerComponent = ref(null),
    transitionsComponent = ref(null),
    physicsLoaded = ref(false),
    cursor = ref(),
    sceneLoaded = ref(false),
    terrainLoaded = ref(false),
    intersected = ref(),
    loadAsset = (a) => {

        console.log("entities.value")
        console.log(entities.value)
        console.log(entities.value[a])
        console.log(a)
        let r = entities.value[a].node.clone();
        if (a == "large-sakura.glb") {
            r.scale.set(3, 3, 3);
        }
        if (a == "sakura-tree.glb") {

            console.log("scale")
            r.scale.set(0.02, 0.02, 0.02);

        }
        scene.add(r);
        r.position.copy(pointer.position);
        //selectedEntity.value = entities.value[a];

    },
    createCursor = () => {

        const cursorGeometry = new THREE.BufferGeometry(),
         vertices = new Float32Array([
          -0.1, 0, 0,
          0.1, 0, 0,
          0, -0.1, 0,
          0, 0.1, 0
        ]);
        cursorGeometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));

        const cursorMaterial = new THREE.MeshBasicMaterial({ color: 0x00ff00 });

        cursor.value = new THREE.Mesh(cursorGeometry, cursorMaterial);
        scene.add(toRaw(cursor.value));

    },
    loadAssets = () => {

        for (var l in entities.value) {

            entityComponent.value.loadGltf(l);
/*
            axios.get(

                `${process.env.VUE_APP_SERVER_URI}public/glb/${l}`,
                {

                    "headers": {
                    }

                }

            ).
                then((response) => {

                    if (response.status === 200) {

                        const n = response.data;
                        entityComponent.value.loadGltf(n);

                    }

                }).
                catch((error) => {
                });

*/
        }

    },
    loadModel = () => {

        axios.get(

            `${process.env.VUE_APP_SERVER_URI}public/l.json`,
            {

                "headers": {
                }

            }

        ).
            then((response) => {

                if (response.status === 200) {

                    const n = response.data;
                    entityComponent.value.loadModel(n.model.entities[0],0);

                }

            }).
            catch((error) => {
            });

    },
    createRigidBody = (node) => {

      physicsComponent.value.createRigidBody(node.node, node.shape, node.mass, node.inertia, node.kinematic);

    },
    captureKeyPress = (o) => {

      keyPress.value = o.value;

    },
    captureMouseInfo = (o) => {

      mouseInfo.value = o.value;

    },
    initScene = () => {

      console.log('INIT SCENE')

      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);

      renderer.shadowMap.enabled = true;
      renderer.shadowMap.type = THREE.PCFSoftShadowMap; 

      scene.environmentIntensity = 0.4;
      renderer.toneMapping = THREE.ACESFilmicToneMapping;
      //renderer.toneMapping = THREE.LinearToneMapping;
      renderer.toneMappingExposure = 1.0;

      three.value.appendChild(renderer.domElement);
      scene.add(axesHelper);
      scene.add(gridHelper);

        const textureLoader = new THREE.TextureLoader();

        //loader = new GLTFLoader()
        //    .setMeshoptDecoder(MeshoptDecoder);

        //loader = new UltraHDRLoader();
				//loader.setDataType( THREE.HalfFloatType );
        const loader = new GainMapLoader(renderer);

				const panoSphereGeo = new THREE.SphereGeometry( 11000, 64,  64);

				// Create the panoramic sphere material
				const panoSphereMat = new THREE.MeshStandardMaterial( {
					side: THREE.BackSide,
					displacementScale: - 100.0,
          color: new THREE.Color(10, 10, 10)
				});

				// Create the panoramic sphere mesh
				const sphere = new THREE.Mesh( panoSphereGeo, panoSphereMat );

/*
				textureLoader.load( backgroundSkybox, function ( texture ) {

					texture.colorSpace = THREE.SRGBColorSpace;
					texture.minFilter = THREE.NearestFilter;
					texture.generateMipmaps = false;
					sphere.material.map = texture;

				});


				textureLoader.load( backgroundSkyboxDepth, function ( depth ) {

					depth.minFilter = THREE.NearestFilter;
					depth.generateMipmaps = false;
					sphere.material.displacementMap = depth;

          scene.add(sphere);

				});

*/




/*
      const loadEnvironment = function ( resolution = '4k', type = 'FloatType' ) {
        loader.setDataType( THREE[ type ] );
          loader.load( `/public/textures/japan_sky_${resolution}.hdr.jpg`, function ( texture ) {

              texture.mapping = THREE.EquirectangularReflectionMapping;
              texture.needsUpdate = true;

          });

      };

      loadEnvironment();
*/


      const pmremGenerator = new THREE.PMREMGenerator(renderer);
      (async ()=>{

          const textureLoader = new THREE.TextureLoader();
          const skyboxTexture = textureLoader.load('/public/textures/japan-skybox-nogain.jpg');
          scene.background = skyboxTexture;
          scene.background.mapping = THREE.EquirectangularReflectionMapping;

          const result = await loader.loadAsync([
            '/public/textures/japan-skybox-nogain-512.jpg',
            '/public/textures/japan-skybox-gainmap-512.jpg',
            '/public/textures/japan-skybox-gainmap.json']);

          const envMap = pmremGenerator.fromEquirectangular(result.renderTarget.texture).texture;
          scene.environment = envMap;
          scene.environment.mapping = THREE.EquirectangularReflectionMapping;
          pmremGenerator.dispose();

          //scene.background = result.renderTarget.texture;
          //scene.background.mapping = THREE.EquirectangularReflectionMapping;

      })();

				//loader.setDataType( THREE.HalfFloatType );

      // Lighting
      //const ambientLight = new THREE.AmbientLight(0xffffff, 0.25);
      //scene.add(ambientLight);

      sun.name = "sun";
      //sun.position.set(sunDistance.value, sunDistance.value, -sunDistance.value);

      sun.position.set(
          sunDistance.value * Math.cos(latitude.value),
          sunDistance.value * Math.sin(latitude.value),
          0                                     
      );

      const sunLight = new THREE.DirectionalLight(0xffffff, 2.5);
      sunLight.castShadow = true;
      sunLight.shadow.mapSize.width = 1024;
      sunLight.shadow.mapSize.height = 1024;
      sunLight.shadow.camera.near = 0.5;
      sunLight.shadow.camera.far = 50000;
      sunLight.shadow.bias = -0.00005; 
      sunLight.name = 'sunLight';

      sunLight.shadow.camera.left = -500;
      sunLight.shadow.camera.right = 500;
      sunLight.shadow.camera.top = 500;
      sunLight.shadow.camera.bottom = -500;

/*
      const sunLight = new THREE.PointLight(0xffffff, 100000, 1000, 2);
      sunLight.castShadow = true;
      sunLight.shadow.mapSize.width = 1024;
      sunLight.shadow.mapSize.height = 1024;
*/

      sun.add(sunLight);
      scene.add(sun);

      // debug helper
      // scene.add(new THREE.CameraHelper(sunLight));

      // can only be loaded after the renderer is setup

      sceneLoaded.value = true;
      console.log('init scene end')

      setTimeout(()=> {

          terrainLoaded.value = true;

      }, 5000);

    },
    addEntity = (entity) => {

        scene.add(entity.node);
        entities.value[entity.name] = entity;
        selectedEntity.value = entity;

    },
    loadEntity = (entity) => {

        entities.value[entity.name] = entity;
        selectedEntity.value = entity;

    },
    addToScene = (e) => {

        switch (e.type) {

          case "box":
            if (!entities.value[e.name]) {

              entities.value[e.name] = {}

            }
            scene.add(e.node);
            break;

        }

    },
    removedRigidBody = (e) => {
    },
    animate = function (delta) {

        // start stats
        fpsstats.value.begin();
        memstats.value.begin();

        // animations
        for (const i in entities.value) {

            if (entities.value[i].animationMixer) {

               entities.value[i].animationMixer.update(delta);

            }

        }

        // camera
        camera.value.process();

        // action
        actionsComponent.value.process();

        // process transitions
        transitionsComponent.value.process();

        // terrain
        if (terrainLoaded.value == true) {

            //terrainComponent.value.process();

        }

        // Update the sun's position in a circular orbit
        sunAngle.value += 0.0001; // Rotation speed (adjust as needed)

        sun.position.set(
            Math.cos(sunAngle.value) * sunDistance.value * Math.cos(latitude.value),
            Math.sin(sunAngle.value) * sunDistance.value * Math.cos(latitude.value),
            Math.sin(latitude.value) * sunDistance.value
        );

/*
        sun.position.set(
            0,
            Math.sin(sunAngle.value) * sunDistance.value,
            Math.cos(sunAngle.value) * sunDistance.value 
        );

        sun.position.set(
            Math.sin(sunAngle.value) * sunDistance.value,
            sun.position.y,
            Math.cos(sunAngle.value) * sunDistance.value
        );
*/

        const cam = camera.value.get();
        const sunLight = sun.getObjectByName('sunLight');
        sunLight.target.position.copy(cam.position);
        sunLight.target.updateMatrixWorld();

        // render
        renderer.render(scene, camera.value.camera);

        // composer
        toRaw(composerComponent.value.composer).render();

        // end stats
        fpsstats.value.end();
        memstats.value.end();

        // repeat
        requestAnimationFrame( () => { animate(clock.getDelta()) });

    },
    showStats = () => {
        setTimeout(()=>{
            showStats();
            //console.log("renderer")
            //console.log(renderer)
        }, 5000);
    },
    rayCastPointer = () => {

        const cam = camera.value.get(),
          mousePos = new THREE.Vector3(mouseInfo.value.clientX, mouseInfo.value.clientY, 0.5),
          forward = new THREE.Vector3(0, 0, 1);

        const raycaster = new THREE.Raycaster(),
          cursor = new THREE.Vector2();

        cursor.x = ( mouseInfo.value.clientX / window.innerWidth ) * 2 - 1;
        cursor.y = - ( mouseInfo.value.clientY / window.innerHeight ) * 2 + 1;

        raycaster.setFromCamera(cursor, cam);

        const intersects = raycaster.intersectObjects(scene.children
          .filter(object => (object !== gridHelper && object !== axesHelper))); 

        if (intersects.length == 0)
          return;

        //const intersect = filterIntersects(intersects);
        const intersect = intersects[0];

        if (intersect) {

            const point = intersect.point,
              node = intersect.object,
              vector = point.project(cam),
              x = (vector.x + 1) / 2 * window.innerWidth,
              y = -(vector.y - 1) / 2 * window.innerHeight,
              div = document.createElement('div');

            // Create a data object of the click interaction
            const obj = {
               point,
               node,
               vector,
               x,y,
               div
            }

            return obj;

        } else {

            return;

        }

    },
    /*
     * Here we filter for visual model objects.
     * TODO : expand this for selectable physics, armature and whatnot
     */
    filterIntersects = (intersects) => {

        for (let i=0; i < intersects.length; i++) {

            const node = intersects[i].object;

            if ((node.type == "Mesh" || node.type == "SkinnedMesh")
              && node.visible == true) {

                return intersects[i];

            }

        }

    },
    getRootNode = (node, callback) => {

        if (node?.parent?.parent == null) {

            callback(node);

        } else if (node.parent) {

            getRootNode(node.parent, callback)

        } else {

            callback();

        }

    },
    addStats = () => {

        // Show FPS panel (panel 0)
        fpsstats.value.showPanel(0);

        // Create a container for FPS stats
        const fpsContainer = document.createElement('div');
        fpsContainer.style.position = 'absolute';
        fpsContainer.style.top = '0px';
        fpsContainer.style.left = '0px';
        three.value.appendChild(fpsContainer);
        fpsContainer.appendChild(fpsstats.value.dom);

    },
    handleResize = () => {

        // Update camera aspect ratio and renderer size on window resize
        //camera.value.update(width.value, height.value);
        //renderer.setSize(width.value, height.value);
 				//composerComponent.value.composer.setSize(width.value, height.value);

        camera.value.camera.aspect = window.innerWidth / window.innerHeight;
				camera.value.camera.updateProjectionMatrix();
				renderer.setSize( window.innerWidth, window.innerHeight );

        // composerComponent.value.composer.setSize(window.innerWidth, window.innerHeight);

    };

    provide('THREE', THREE);
    provide('scene', scene);
    provide('keyPress', keyPress);
    provide('mouseInfo', mouseInfo);
    provide('renderer', renderer);
    provide('camera', camera);
    provide('Ammo', ammoLib);
    provide('utils', utils);

    onMounted(() => {

        initScene();
        showStats();
        loadAssets();

        pointer.name = 'pointer';
        scene.add(pointer);

        setTimeout(()=> {

            animate(1);
            physicsComponent.value.render();

            // add camera to scene (optional, used if you want to attach objects to the scene)
            scene.add(camera.value.camera);

            camera.value.camera.position.set(0,0,20);


        }, 1000);

        createCursor();

        addStats();

        window.addEventListener('resize', handleResize);

        watch(

            () => selectedEntity.value,

            (first, second) => {

                //selectedEntity.value.node.position.copy(pointer.position);

            }
        );

        watch(
            () => keyPress.value,

            (first, second) => {

                if (keyPress.value.m == true) {

                    loadModel();

                }

                if (keyPress.value['1'] == true) {

                    entityComponent.value.createSphere({x:0.5,y:15,z:15},{x:0,y:10,z:5});

                }

                if (keyPress.value['2'] == true) {

                    entityComponent.value.createBox({x:1,y:1,z:1},{x:0,y:10,z:5});
                
                }

                if (keyPress.value['0'] == true) {

                    actionsComponent.value.pistol(entities.value.cyberGirl, 'pistol');

                }

                if (keyPress.value['9'] == true) {

                    const act = entities.value.cyberGirl;
                    act.animationMixer.clipAction(act.animations[1]).play();

                }

                if (keyPress.value['8'] == true) {

                    const act = entities.value.cyberGirl;
                    act.animationMixer.clipAction(act.animations[2]).play();

                }

                if (keyPress.value['7'] == true) {

                    const act = entities.value.cyberGirl;
                    act.animationMixer.clipAction(act.animations[3]).play();

                }

                if (keyPress.value['6'] == true) {

                    const act = entities.value.cyberGirl;
                    act.animationMixer.clipAction(act.animations[4]).play();

                }

                if (keyPress.value['5'] == true) {

                    const act = entities.value.cyberGirl;
                    act.animationMixer.clipAction(act.animations[5]).play();

                }

                if (keyPress.value['4'] == true) {

                    const act = entities.value.cyberGirl;
                    act.animationMixer.clipAction(act.animations[6]).play();

                }

                if (keyPress.value['['] == true) {

                    const act = entities.value.cyberGirl;
                    act.animationMixer.clipAction(act.animations[1]).play();

                }

                if (keyPress.value[']'] == true) {

                    const act = entities.value.cyberGirl;
                    for (const i in act.animations) {

                        act.animationMixer.clipAction(act.animations[i]).reset();

                    }

                }

                if (keyPress.value.p == true) {

                    physicsComponent.value.togglePhysicsVisibility();

                }

                if (keyPress.value.i == true) {

                    showInsertModal.value = true;

                }

                if (keyPress.value.c == true) {

                    const cam = camera.value.get(),
                     ray = rayCastPointer(),
                     target = new THREE.Vector3(0, 0, 1).unproject(cam);

                    if (!target) {

                      target.copy(new THREE.Vector3(0, 0, -1))

                    }

                    const fireVec = target.clone().sub(cam.position).normalize();

                    entityComponent.value.createSphere({x:0.1,y:10,z:10}, cam.position, null, fireVec.multiplyScalar(10));

                }

            },
            { deep: true }

        );

        watch(

            () => mouseInfo.value,

            (first, second) => {

                camera.value.orient();

/*
                if (mouseInfo.value.deltaX != 0 || mouseInfo.value.deltaY != 0) {

                    let selected = game.getState("SELECTED");

                    if (debounceKey['mouseMove'] != true) {

                        debounceKey['mouseMove'] = true;

                        const obj = rayCastPointer();

                        if (obj) {

                            game.setHover(obj);

                        }

                        // actionsComponent.value.hover(obj);

                        setTimeout(()=> {

                            debounceKey['mouseMove'] = false;

                        }, 1000);

                    }

                }
*/

                if (mouseInfo.value.buttons == 1
                 && mouseInfo.value.dragDeltaX == 0
                 && mouseInfo.value.dragDeltaY == 0) {

                    if (debounceKey['ray'] != true) {

                        debounceKey['ray'] = true;

                        const mouse = new THREE.Vector2();
                        mouse.x = (mouseInfo.value.clientX / window.innerWidth) * 2 - 1;
                        mouse.y = -(mouseInfo.value.clientY / window.innerHeight) * 2 + 1;

                        const raycaster = new THREE.Raycaster();
                        raycaster.setFromCamera(mouse, camera.value.get());

                        // Calculate objects intersecting the ray
                        const intersects = raycaster.intersectObjects(scene.children);

                        if (intersects.length > 0) {

                            const intersectionPoint = intersects[0].point;
                            pointer.position.copy(intersectionPoint);
                            console.log(intersects[0])

                        }

                        const obj = rayCastPointer();

                        if (obj) {

                            const p = new THREE.Vector3();

                            pointer.visible = true;

                            game.addSelected(obj)

                            actionsComponent.value.selected(obj);

                            var opts = {
                              edgeStrength: 3.0,
                              edgeGlow: 0.0,
                              edgeThickness: 2.0,
                              pulsePeriod: 0,
                              rotate: false,
                              usePatternTexture: false
                            };

                            // something broken here 
                            // composerComponent.value.addOutline(obj, opts);

                            // debug terrain
/*
                            let t = obj.node;
                            let n = t?.userData?.terrainInfo?.neighbors?.north;
                            let e = t?.userData?.terrainInfo?.neighbors?.east;
                            let s = t?.userData?.terrainInfo?.neighbors?.south;
                            let w = t?.userData?.terrainInfo?.neighbors?.west;
                            if (n) {
                                n.material.color = 0xff0000;
                                n.material.needsUpdate = true;
                            }
                            if (e) {
                                e.material.color = 0xffff00;
                                e.material.needsUpdate = true;
                            }
                            if (s) {
                                s.material.color = 0x00ff00;
                                s.material.needsUpdate = true;
                            }
                            if (w) {
                                w.material.color = 0x0000ff;
                                w.material.needsUpdate = true;
                            }
*/
                        } else {

                            game.remSelected(0, 1);

                        }

                    }

                    setTimeout(()=> {

                        debounceKey['ray'] = false;

                    }, 100);

                } else {

                    mouseHeld.value = false;

                }

            },
            { deep: true }

        );

    });

    onBeforeUnmount(() => {

        // Remove event listener and clean up resources
        window.removeEventListener('resize', handleResize);
        renderer.dispose();

    });

    AmmoJS().then((AmmoLib) => {

        ammoLib.value = AmmoLib;
        //console.log('physicsLoaded')
        //console.log('ammoLib.value')
        physicsLoaded.value = true;

    });

    return {
      "localize": translation.localize,
      rootNode,
      sun,
      sunAngle,
      latitude,
      three,
      showStats,
      keyPress,
      axesHelper,
      gridHelper,
      captureMouseInfo,
      captureKeyPress,
      mouseHeld,
      currentX,
      currentY,
      deltaX,
      deltaY,
      initScene,
      scene,
      axios,
      fpsstats,
      memstats,
      loadModel,
      loadAssets,
      loadAsset,
      addEntity,
      loadEntity,
      handleResize,
      camera,
      entityComponent,
      actionsComponent,
      physicsComponent,
      composerComponent,
      transitionsComponent,
      createRigidBody,
      filterIntersects,
      getRootNode,
      addToScene,
      sceneLoaded,
      physicsLoaded,
      ammoLib,
      renderer,
      cursor,
      entities,
      animate,
      pointerGeom,
      pointerMat,
      pointer,
      cardGameComponent,
      terrainComponent,
      terrainLoaded,
      entities,
      intersected,
      rayCastPointer,
      game,
      debounceKey,
      addStats,
      showInsertModal,
      selectedEntity,
      sunDistance
    };
  },
};
</script>

<style>
body {
  margin: 0;
}

#threeContainer {
  width: 100%;
  height: 100vh; /* Adjust as needed */
}
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
}
canvas {
    user-select: none; /* Prevents text selection */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    
    pointer-events: all; /* Ensures the canvas gets all pointer events */
}
</style>
